import { Box } from "@material-ui/core"; // todo remove Box
import { TypographyComponent } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { displayBudget } from "../../../../../../lib";
import { ToggleText } from "../../../../../../ui/toggleText";
import { SelectionContext } from "../../../../selection.context";
import styles from "./initiative-objective.styles.module.scss";

const InitiativeObjectives = () => {
  const { t } = useTranslation();

  const { budget: initBudget, objectives } = useContext(SelectionContext);
  const budget = displayBudget(initBudget);

  const budgetDetails = budget && (
    <Box display="flex">
      <TypographyComponent styling={"p2"} boldness={"medium"}>
        {t("Budget")}
      </TypographyComponent>
      <TypographyComponent styling={"p2"} rootClassName={styles.budgetValue}>
        {budget}
      </TypographyComponent>
    </Box>
  );

  return (
    <div className={styles.root}>
      <Box display="flex" alignItems="center" justifyContent="space-between" className={styles.titleWrapper}>
        <TypographyComponent styling={"p2"} boldness={"medium"} rootClassName={styles.objectiveTitle}>
          {t("Objectives")}
        </TypographyComponent>
        {budgetDetails}
      </Box>
      <ToggleText rootClassName={styles.toggleText} text={objectives} />
    </div>
  );
};

export default InitiativeObjectives;
