import Popper from "@mui/material/Popper";
import { ClickAwayListener, PopperPlacementType, PopperProps } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./chart-popper.styles.module.scss";
import classNames from "classnames";
import { Checkbox, IconButton, ProductLogo, TypographyComponent } from "gx-npm-ui";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowRoundedTipWithBorder, CloseIcon } from "gx-npm-icons";
import { ChartPopperContent } from "../../../survey-table.constants";
import { useTranslation } from "react-i18next";
import { FilterContext } from "../../../context/filter/filter.context";
import { SurveyTableContext } from "../../../context/survey-table/survey-table.context";

type ChartPopperProps = {
  onClose: (event: MouseEvent) => void;
  open: boolean;
  anchor: React.ReactNode;
  chartPopperContent: ChartPopperContent | null;
};

export const ChartPopperComponent: FC<ChartPopperProps> = ({ onClose, open, anchor, chartPopperContent }) => {
  const { responseOptionIds, setResponseOptionIds, setResponseFilteredCategories, productId, setProductId } =
    useContext(FilterContext);

  const { surveyCategories } = useContext(SurveyTableContext);
  const [currentPlacement, setCurrentPlacement] = useState<PopperPlacementType>("bottom");
  const { t } = useTranslation();
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false);

  useEffect(() => {
    if (chartPopperContent) {
      const { chartData, product } = chartPopperContent;
      const isResponseIdAlreadyAdded = responseOptionIds.includes(chartData.responseOptionId);
      const isSameProduct = productId === product.id;

      setIsCheckboxSelected(isResponseIdAlreadyAdded && isSameProduct);
    }
  }, [chartPopperContent, productId, responseOptionIds]);

  const priorityNameMap: Record<number, { label: string; value: string }> = Object.freeze({
    1: { value: t("LOW"), label: "Low" },
    2: { value: t("MED"), label: "Medium" },
    3: { value: t("HIGH"), label: "High" },
  });

  const priorityStyleMap: Record<number, string> = {
    1: styles.priorityOne,
    2: styles.priorityTwo,
    3: styles.priorityThree,
  };

  const getStyleForPriority = (priority: number): string => priorityStyleMap[priority] ?? "";

  const handleCheckboxChange = (_event: MouseEvent, isChecked: boolean) => {
    if (chartPopperContent) {
      const { product, chartData } = chartPopperContent;
      const newProductId = product.id;
      const responseOptionId = chartData.responseOptionId;
      let newResponseOptionIds = responseOptionIds;

      if (isChecked) {
        setProductId(newProductId);
        newResponseOptionIds =
          newProductId === productId ? [...responseOptionIds, responseOptionId] : [responseOptionId];
        setResponseOptionIds(newResponseOptionIds);
      } else if (productId === newProductId) {
        newResponseOptionIds = responseOptionIds.filter((id) => id !== responseOptionId);
        setResponseOptionIds(newResponseOptionIds);
      }

      if (newResponseOptionIds.length === 0) {
        setResponseFilteredCategories([]);
        setProductId("");
        return;
      }

      const filteredCategories = surveyCategories.map((category) => {
        return {
          ...category,
          requirements: category.requirements.filter((requirement) => {
            return newResponseOptionIds.includes(
              product?.categories[category.id]?.requirements[requirement.id]?.responseOptionId || ""
            );
          }),
        };
      });
      setResponseFilteredCategories(filteredCategories);
    }
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <span>
        <Popper
          open={open}
          anchorEl={anchor as PopperProps["anchorEl"]}
          placement={currentPlacement}
          className={styles.root}
          onClick={(e) => e.stopPropagation()}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 12],
              },
            },
            {
              name: "eventListeners",
              enabled: true,
              options: {
                scroll: true,
                resize: true,
              },
              fn({ state }) {
                setCurrentPlacement((prevState) =>
                  currentPlacement !== state.placement ? state.placement : prevState
                );
              },
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["top", "bottom", "left", "right"],
              },
            },
          ]}
        >
          <div className={classNames(styles.wrapper)}>
            <section className={styles.header}>
              <IconButton
                ariaLabel={"close"}
                onClick={onClose}
                className={styles.closeBtnWrapper}
                hoverFill={colorPalette.neutrals.iron.hex}
                hoverBackgroundColor={colorPalette.basic.white.hex}
              >
                <CloseIcon />
              </IconButton>
            </section>
            {chartPopperContent && (
              <div className={styles.chartPopperRoot}>
                <div className={styles.productInfo}>
                  <ProductLogo
                    name={chartPopperContent.product.name}
                    imageWidth={"24px"}
                    imageHeight={"24px"}
                    logo={chartPopperContent.product.imageLoc}
                  />
                  <TypographyComponent styling={"p4"} boldness={"medium"} rootClassName={styles.productName}>
                    {chartPopperContent.product.name}
                  </TypographyComponent>
                </div>
                <div className={styles.responseOptionName}>
                  <TypographyComponent styling={"p3"} boldness={"medium"}>
                    {chartPopperContent.chartData.label} ({chartPopperContent.chartData.value})
                  </TypographyComponent>
                </div>
                <div className={styles.requirementHeading}>
                  <TypographyComponent styling={"p5"} boldness={"regular"} color={"iron"}>
                    {t("Requirement breakdown by priority")}
                  </TypographyComponent>
                </div>
                {Object.entries(chartPopperContent.prioritiesCount)
                  .sort(([keyA], [keyB]) => parseInt(keyB, 10) - parseInt(keyA, 10))
                  .map(([key, value]) => {
                    const priorityLevel = parseInt(key, 10);
                    const priority = priorityNameMap[priorityLevel];
                    return (
                      <div key={key} className={styles.chartPriorityList}>
                        <div className={styles.priorityColumn}>
                          <div className={classNames(getStyleForPriority(priorityLevel), styles.priorityBadge)}>
                            <TypographyComponent rootClassName={styles.priority} styling={"p5"} boldness={"regular"}>
                              {priority.value}
                            </TypographyComponent>
                          </div>
                          <TypographyComponent styling={"p4"} boldness={"regular"} color={"carbon"}>
                            {priority.label} {t("Priority")}
                          </TypographyComponent>
                        </div>
                        <TypographyComponent styling={"p4"} boldness={"medium"} color={"carbon"}>
                          {value}
                        </TypographyComponent>
                      </div>
                    );
                  })}
                <div className={styles.filterCheckbox}>
                  <Checkbox
                    label={"Filter by this response"}
                    checked={isCheckboxSelected}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
            )}
          </div>
          <span
            className={classNames(styles.arrow, {
              [styles.arrowUpward]: currentPlacement === "bottom",
              [styles.arrowDownward]: currentPlacement === "top",
              [styles.arrowLeft]: currentPlacement === "right",
              [styles.arrowRight]: currentPlacement === "left",
            })}
          >
            {<ArrowRoundedTipWithBorder />}
          </span>
        </Popper>
      </span>
    </ClickAwayListener>
  );
};
