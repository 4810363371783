// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t0YBqbkIOqavgNinGdSj{margin-top:32px}.t0YBqbkIOqavgNinGdSj .F2csDIMFRm6dm0a9_u7p{color:var(--color-neutrals-iron)}.sgJmLjKkESuTM95zzsGf{border-top:1px var(--color-neutrals-silver) solid;display:flex;justify-content:space-between;align-items:center;padding-bottom:8px;padding-top:8px}.aqyhQpZi8CEQuBf9ATjC{display:inline-block}.vu0y0eEBlOQxefLwGARp{display:inline-block;float:right}.F2csDIMFRm6dm0a9_u7p{margin-bottom:12px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/initiative-details-card/components/details-checklist-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEA,4CAEE,gCAAA,CAIJ,sBACE,iDAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,oBAAA,CAGF,sBACE,oBAAA,CACA,WAAA,CAGF,sBACE,kBAAA","sourcesContent":[".checklistContainer {\n  margin-top: 32px;\n\n  .checklistTitle {\n    /* todo rm this */\n    color: var(--color-neutrals-iron);\n  }\n}\n\n.checklistSection {\n  border-top: 1px var(--color-neutrals-silver) solid;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-bottom: 8px;\n  padding-top: 8px;\n}\n\n.checklistSectionName {\n  display: inline-block;\n}\n\n.checklistSectionStatus {\n  display: inline-block;\n  float: right;\n}\n\n.checklistTitle {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checklistContainer": `t0YBqbkIOqavgNinGdSj`,
	"checklistTitle": `F2csDIMFRm6dm0a9_u7p`,
	"checklistSection": `sgJmLjKkESuTM95zzsGf`,
	"checklistSectionName": `aqyhQpZi8CEQuBf9ATjC`,
	"checklistSectionStatus": `vu0y0eEBlOQxefLwGARp`
};
export default ___CSS_LOADER_EXPORT___;
