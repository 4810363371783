// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VyzS8KK_Dfv6esuEBSMg{margin-top:24px}`, "",{"version":3,"sources":["webpack://./src/sections/survey/survey-table/components/screened-out-products/screened-out-products.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".root {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `VyzS8KK_Dfv6esuEBSMg`
};
export default ___CSS_LOADER_EXPORT___;
