/**
 * add set of query params to and existing url path
 * @param fullUrlPath complete url including protocol and domain with path and existing query params allowed
 * @param newParamsKeyValueMap key value pair the name of the query param and the value, { "ref": "fancy" } -> ?ref=fancy
 */
const addQueryParams = (fullUrlPath: string, newParamsKeyValueMap: Record<string, boolean | number | string> = {}) => {
  let url: URL;
  try {
    url = new URL(fullUrlPath);
  } catch (error) {
    console.warn("unable to create url, did not add new params", error);
    return fullUrlPath;
  }
  Object.entries(newParamsKeyValueMap).forEach(([key, value]) => {
    url.searchParams.append(key, `${value}`);
  });
  return url.toString();
};

export default addQueryParams;
