import { Category, Product } from "../../survey-table.constants";

export type ChartData = {
  value: number;
  label: string;
  sliceIndex?: number;
  responseOptionId: string;
};

export type ChartProps = {
  data: ChartData[];
  total: number;
  category: Category;
  product: Product;
};

export const WIDTH = 200;
export const HEIGHT = 200;
export const INNER_RADIUS = 60;
export const OUTER_RADIUS = 80;
export const HOVER_PADDING = 6;
