import styles from "./no-requirements-error.styles.module.scss";
import { Button, TypographyComponent } from "gx-npm-ui";
import classNames from "classnames";
import { InfoIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type NoRequirementsErrorProps = {
  handleClick: () => void;
  disabled: boolean;
};

export const NoRequirementsErrorComponent: FC<NoRequirementsErrorProps> = ({ handleClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <InfoIcon fillPath={colorPalette.status.poisonCherry.hex} />
        </div>
        <TypographyComponent rootClassName={styles.textContent} boldness={"medium"} styling={"p3"}>
          {t(
            "All questions that were part of your questionnaire have been deleted. To resolve this, manage your questionnaire setup and select at least one category to be included in your questionnaire."
          )}
        </TypographyComponent>
        <div className={styles.navButton}>
          <Button onClick={handleClick} disabled={disabled} rootClassName={classNames("btn-primary")}>
            {t("Questionnaire Setup")}
          </Button>
        </div>
      </div>
    </div>
  );
};
