import { createContext, ReactNode, useState } from "react";
import { Category, Product, ResponseOption, SurveyTableContextValue } from "../../survey-table.constants";

const SurveyTableContext = createContext<SurveyTableContextValue>({} as SurveyTableContextValue);

const SurveyTableContextProvider = ({ children }: { children: ReactNode }) => {
  const [surveyProducts, setSurveyProducts] = useState<Product[]>([]);
  const [surveyCategories, setSurveyCategories] = useState<Category[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [surveyResponseOptions, setSurveyResponseOptions] = useState<ResponseOption[]>([]);

  const contextValue = {
    filteredCategories,
    surveyProducts,
    surveyCategories,
    surveyResponseOptions,
    setFilteredCategories,
    setSurveyCategories,
    setSurveyProducts,
    setSurveyResponseOptions,
  };

  return <SurveyTableContext.Provider value={contextValue}>{children}</SurveyTableContext.Provider>;
};

export { SurveyTableContext, SurveyTableContextProvider };
