import { createContext, ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { CarouselContextValue, Product } from "../../../../app.types";
import { getStoredJsonItem, setStoredJsonItem } from "../../../../ui/dragAndDropList/lib";

const CarouselContext = createContext<CarouselContextValue>({} as CarouselContextValue);

const CarouselContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [dockedReqCatIdx, setDockedReqCatIdx] = useState<number>(-1);
  const [reqCatOpenIdxList, setReqCatOpenIdxList] = useState<number[]>([]);
  const [currentPos, setCurrentPos] = useState<number>(0);
  const [showStickyHeader, setShowStickyHeader] = useState<boolean>(false);
  const [visibleProducts, setVisibleProducts] = useState<Product[]>([]);

  const { initiativeId = "" } = useParams();

  const updateSessionStorage = (openCopy: number[]) => {
    const sessionStorageId = `initiatives-evaluation-${initiativeId}`;
    const sessionData = getStoredJsonItem(sessionStorageId);
    setStoredJsonItem(sessionStorageId, {
      ...sessionData,
      requirementsOpenIdxList: openCopy,
    });
  };

  const onExpandToggleReqCatClick = (clickIndex: number) => {
    const openCopy = reqCatOpenIdxList.includes(clickIndex)
      ? reqCatOpenIdxList.filter((index) => index !== clickIndex)
      : [...reqCatOpenIdxList, clickIndex];

    setReqCatOpenIdxList(openCopy);
    updateSessionStorage(openCopy);
  };

  const onReqDockingTrigger = (reqCatIndex: number) => {
    const isReqCatOpen = reqCatOpenIdxList.includes(reqCatIndex);

    if (isReqCatOpen && reqCatIndex !== -1) {
      setShowStickyHeader(true);
      setDockedReqCatIdx(reqCatIndex);
    } else {
      setShowStickyHeader(false);
      setDockedReqCatIdx(-1);
    }
  };

  const contextValue = {
    dockedReqCatIdx,
    reqCatOpenIdxList,
    currentPos,
    showStickyHeader,
    visibleProducts,
    setDockedReqCatIdx,
    setReqCatOpenIdxList,
    setCurrentPos,
    setShowStickyHeader,
    onExpandToggleReqCatClick,
    onReqDockingTrigger,
    setVisibleProducts,
  };

  return <CarouselContext.Provider value={contextValue}>{props.children}</CarouselContext.Provider>;
};

export { CarouselContext, CarouselContextProvider };
