import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Collapse, makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { ExclamationCircledIcon, RevertIcon } from "gx-npm-icons";
import ExclamationErrorIcon from "./exclamation-error.icon";
import PencilEditIcon from "./pencil-edit.icon";
import {
  ButtonLoader,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  MiniButton,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import EditCategoryWeightDialog from "../../lists/components/categoryWeightDialog";
import styles from "./reqs-post-publish-inline-alert.styles";
import { GCOM_280_GCOM_4449__fixCategoryWeight } from "../../../../lib/feature-flags";

const propTypes = {
  initId: PropTypes.string,
  isInvalidWeightTotal: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isPublishedInEdit: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  isContributor: PropTypes.bool,
  onApplyUpdateButtonClick: PropTypes.func,
  onRevertButtonClick: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const ReqsPostPublishInlineAlert = ({
  initId = "",
  isInvalidWeightTotal = false,
  isProcessing = true,
  isPublishedInEdit = true,
  isViewOnly = false,
  isContributor = false,
  onApplyUpdateButtonClick = () => {},
  onRevertButtonClick = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmationTooltipText, setConfirmationTooltipText] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isGCOM4449FixCategoryWeightOn = useFeatureFlag(GCOM_280_GCOM_4449__fixCategoryWeight);

  useEffect(() => {
    setTimeout(() => {
      setShowBanner(true);
    }, 1000);
  }, []);

  useEffect(() => {
    let text = t("Apply requirement updates to your questionnaire and/or scorecard.");
    if (isViewOnly || isContributor) {
      text = t("This action available only for the evaluation owners.");
    } else if (!isGCOM4449FixCategoryWeightOn && isInvalidWeightTotal) {
      text = t("The total weight of all requirements must equal 100%.");
    }
    setConfirmationTooltipText(text);
  }, [isInvalidWeightTotal, isViewOnly, isContributor, t, isGCOM4449FixCategoryWeightOn]);

  return (
    <FeatureFlagBooleanContainer flagName={GCOM_280_GCOM_4449__fixCategoryWeight}>
      <FeatureFlagBooleanOff>
        <Collapse in={showBanner}>
          <div className={classNames("inline-alert", classes.container)}>
            <div className={classes.message}>
              <div className={classes.icon}>
                <div className={classes.flipIcon}>
                  <ExclamationCircledIcon fillPath={colorPalette.status.darkLemon.hex} />
                </div>
              </div>

              <TypographyComponent boldness={"medium"} rootClassName={"warning-message"} styling={"p3"}>
                {isInvalidWeightTotal
                  ? t("Unable to apply updates. The total weight of all requirements must equal 100%.")
                  : t(
                      "Your requirements have been modified. To update your scorecard and/or questionnaire, apply updates."
                    )}
              </TypographyComponent>
            </div>
            <div className={classes.buttonWrapper}>
              <MiniButton
                ariaLabel={t("revert")}
                disabled={isViewOnly || !isPublishedInEdit}
                onClick={onRevertButtonClick}
                rootClassName={classNames("btn-tertiary ")}
              >
                <RevertIcon />
                {t("Revert all changes")}
              </MiniButton>
              <TooltipV2
                deactivate={isProcessing}
                placement="top"
                PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
                title={confirmationTooltipText}
              >
                <div>
                  <ButtonLoader
                    ariaLabel={t("apply updates")}
                    btnClass="btn-primary"
                    disabled={isViewOnly || isContributor || isInvalidWeightTotal}
                    isLoading={isProcessing}
                    onClick={onApplyUpdateButtonClick}
                  >
                    {t("APPLY UPDATES")}
                  </ButtonLoader>
                </div>
              </TooltipV2>
            </div>
          </div>
        </Collapse>
      </FeatureFlagBooleanOff>
      <FeatureFlagBooleanOn>
        <Collapse in={showBanner}>
          <div
            className={classNames("inline-alert", classes.containerGCOM4449, isInvalidWeightTotal && "invalid-weight")}
          >
            <div className={classes.message}>
              <div className={classes.icon}>
                {isInvalidWeightTotal ? (
                  <ExclamationErrorIcon fillPath={colorPalette.status.poisonCherry.hex} />
                ) : (
                  <div className={classes.flipIcon}>
                    <ExclamationErrorIcon fillPath={colorPalette.status.darkLemon.hex} />
                  </div>
                )}
              </div>

              <TypographyComponent boldness={"medium"} rootClassName={"warning-message"} styling={"p3"}>
                {isInvalidWeightTotal
                  ? `${t("Total category weights must equal 100%.")} ${t(
                      "You will not be able to start the team scorecard until this has been updated."
                    )}`
                  : t(
                      "Your requirements have been modified. To update your scorecard and/or questionnaire, apply updates."
                    )}
              </TypographyComponent>
            </div>
            {isInvalidWeightTotal ? (
              <TooltipV2
                deactivate={!isViewOnly}
                placement="top"
                PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
                title={t("This action is only available to evaluation owners and contributors.")}
              >
                <div>
                  <MiniButton
                    disabled={isViewOnly}
                    onClick={() => setIsDialogOpen(true)}
                    rootClassName={classNames("btn-tertiary ")}
                  >
                    <PencilEditIcon />
                    {t("Edit category weights")}
                  </MiniButton>
                </div>
              </TooltipV2>
            ) : (
              <div className={classes.buttonWrapper}>
                <MiniButton
                  ariaLabel={t("revert")}
                  disabled={isViewOnly || !isPublishedInEdit}
                  onClick={onRevertButtonClick}
                  rootClassName={classNames("btn-tertiary ")}
                >
                  <RevertIcon />
                  {t("Revert all changes")}
                </MiniButton>
                <TooltipV2
                  deactivate={isProcessing}
                  placement="top"
                  PopperProps={{ modifiers: { offset: { offset: "0, 12px, 264px" } } }}
                  title={confirmationTooltipText}
                >
                  <div>
                    <ButtonLoader
                      ariaLabel={t("apply updates")}
                      btnClass="btn-primary"
                      disabled={isViewOnly || isContributor}
                      isLoading={isProcessing}
                      onClick={onApplyUpdateButtonClick}
                    >
                      {t("APPLY UPDATES")}
                    </ButtonLoader>
                  </div>
                </TooltipV2>
              </div>
            )}
          </div>
          <EditCategoryWeightDialog
            initiativeId={initId}
            isOpen={isDialogOpen}
            onClick={() => setIsDialogOpen(false)}
          />
        </Collapse>
      </FeatureFlagBooleanOn>
    </FeatureFlagBooleanContainer>
  );
};

ReqsPostPublishInlineAlert.propTypes = propTypes;
export default ReqsPostPublishInlineAlert;
