import { InitUserRole, useUserInitAccess, UUID } from "gx-npm-lib";
import { TooltipV2 } from "gx-npm-ui";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DeleteProductButtonIcon from "./delete-product-button.icon";
import styles from "./delete-product-button.styles.module.scss";

type DeleteProductButtonProps = { onClick?: () => void };
const DeleteProductButtonComponent = ({ onClick = () => {} }: DeleteProductButtonProps): ReactElement => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { role } = useUserInitAccess(initiativeId);
  const isViewOnly = role !== InitUserRole.OWNER;
  return (
    <TooltipV2
      placement="top"
      title={isViewOnly ? t("This action available only for the evaluation owners.") : t("Delete vendor")}
    >
      <div className={styles.deleteButtonWrapper}>
        <button
          aria-label={t("delete from evaluation")}
          className={styles.deleteButton}
          disabled={isViewOnly}
          onBlur={() => setIsFocused(false)}
          onClick={onClick}
          onFocus={() => setIsFocused(true)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <DeleteProductButtonIcon isHighlighted={!isViewOnly && (isHovered || isFocused)} />
        </button>
      </div>
    </TooltipV2>
  );
};

export default DeleteProductButtonComponent;
