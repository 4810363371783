import styles from "./no-product-view.styles.module.scss";
import { Button, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { navigateToUrl } from "single-spa";
import { useParams } from "react-router-dom";

export const NoProductViewComponent = () => {
  const { t } = useTranslation();
  const { initiativeId } = useParams();

  return (
    <div className={styles.root}>
      <TypographyComponent styling={"p2"} boldness={"medium"}>
        {t("Vendors")} (0)
      </TypographyComponent>
      <TypographyComponent rootClassName={styles.noVendorText} styling={"p3"} boldness={"medium"} color={"iron"}>
        {t("It looks like there are no active vendors in your eval.")}
      </TypographyComponent>
      <Button
        rootClassName={classNames("btn-primary", styles.goToBtn)}
        onClick={() => navigateToUrl(`/s/evaluation/${initiativeId}/products`)}
      >
        {t("Go to vendor list")}
      </Button>
    </div>
  );
};
