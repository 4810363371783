import { colorPalette } from "gx-npm-common-styles";

const requirementsListHeaderStyles = {
  fadeWrapper: {
    width: "100%",
    position: "sticky",
    top: "48px",
    zIndex: "1024",
  },
  flexChild: {
    display: "inline-flex",
    margin: "auto 0",
  },
  flexParent: {
    "& .gx-is-expanded": {
      marginRight: 4,
    },
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    "& > div": {
      padding: "20px 0px 20px 0px",
    },
    "& .gx-req-cat-delete-icon": {
      position: "absolute",
      right: "0",
    },
  },
  reqWeightEdit: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  headerRoot: {
    position: "relative",
    width: "100%",
    "& .gx-spacer": {
      width: "20px",
    },
    "& .gx-item-modified": {
      background: colorPalette.status.lemon.hex,
      borderTopLeftRadius: "8px",
      display: "block",
      height: "100%",
      position: "absolute",
      width: "4px",
    },
    "& .gx-req-cat-weight-label": {
      paddingLeft: "8px",
    },
    "& .gx-req-cat-delete-icon": {
      display: "none",
      flexBasis: "48px",
      margin: "auto",
      marginRight: "20px",
      order: 2,
    },
    "&:hover .gx-req-cat-delete-icon": {
      display: "inline-flex",
    },
    "& .gx-req-cat-invalid-weight-iconGCOM4449": {
      height: "24px",
    },
  },
};
export { requirementsListHeaderStyles };
