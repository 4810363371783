import styles from "./filters.styles.module.scss";
import { MiniButton, TypographyComponent } from "gx-npm-ui";
import { ProductResponseFilterComponent } from "./product-response-filter/product-response-filter.component";
import { useTranslation } from "react-i18next";
import { PriorityFilterComponent } from "./priority-filter/priority-filter.component";
import { useContext, useEffect, useRef } from "react";
import { SurveyTableContext } from "../context/survey-table/survey-table.context";
import { FilterContext } from "../context/filter/filter.context";

export const FiltersComponent = () => {
  const { t } = useTranslation();

  const { setFilteredCategories, surveyCategories } = useContext(SurveyTableContext);
  const filterRef = useRef<HTMLDivElement | null>(null);

  const {
    priorityFilteredCategories,
    responseFilteredCategories,
    setProductId,
    setResponseOptionIds,
    setPriorityId,
    setPriorityFilteredCategories,
    setResponseFilteredCategories,
  } = useContext(FilterContext);

  const handleClearFilters = () => {
    setProductId("");
    setPriorityId([]);
    setResponseOptionIds([]);
    setPriorityFilteredCategories([]);
    setResponseFilteredCategories([]);
  };

  useEffect(() => {
    let rawCategories = surveyCategories;
    if (!priorityFilteredCategories.length && !responseFilteredCategories.length) {
      setFilteredCategories(rawCategories);
      return;
    }
    if (priorityFilteredCategories.length) {
      rawCategories = priorityFilteredCategories;
    }
    if (responseFilteredCategories.length) {
      rawCategories = rawCategories.map((rawCategory) => {
        return {
          ...rawCategory,
          requirements: rawCategory.requirements.filter((requirement) => {
            const responseCategory = responseFilteredCategories.find((category) => category.id === rawCategory.id);
            return responseCategory?.requirements.find(
              (responseRequirement) => responseRequirement.id === requirement.id
            );
          }),
        };
      });
    }
    setFilteredCategories(rawCategories);
  }, [priorityFilteredCategories, responseFilteredCategories, setFilteredCategories, surveyCategories]);

  return (
    <div ref={filterRef} data-testid={"filter-ref"} className={styles.filtersContainer}>
      <TypographyComponent styling={"p3"} boldness={"regular"} color={"iron"}>
        {t("Filter requirements by")}
      </TypographyComponent>
      <PriorityFilterComponent />
      <ProductResponseFilterComponent />
      {(priorityFilteredCategories.length > 0 || responseFilteredCategories.length > 0) && (
        <MiniButton onClick={handleClearFilters}>
          <TypographyComponent styling="p3" boldness="medium" color={"defaultCta"}>
            {t("Clear all filters")}
          </TypographyComponent>
        </MiniButton>
      )}
    </div>
  );
};
