import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MiniButton, QuickStartPopperComponent, SnackbarBanner, TypographyComponent } from "gx-npm-ui";
import { QuestionMarkIcon } from "gx-npm-icons";
import { useParams } from "react-router-dom";
import { colorPalette } from "gx-npm-common-styles";
import { getAsyncRequest, InitUserRole, useCaptureEventsV2, useUserInitAccess, UUID } from "gx-npm-lib";
import { ClientEvent } from "../../../app.constants";
import { ClickAwayListener } from "@mui/material";
import { ToolbarButtonComponent } from "../../../ui/toolbar-button/toolbar-button.component";
import { EditIcon, ExportIcon, SendIcon, PreviewIcon } from "./icons";
import OverviewComponent from "../quick-start/overview/overview.component";
import CreatingTheQuestionnaireComponent from "../quick-start/creating-the-questionnaire/creating-the-questionnaire.component";
import SendingTheQuestionnaireComponent from "../quick-start/sending-the-questionnaire/sending-the-questionnaire.component";
import ViewingResponsesComponent from "../quick-start/viewing-responses/viewing-responses.component";
import SurveyTeamManagementV2 from "../survey-team-management/survey-team-management-v2.component";
import classNames from "classnames";
import styles from "./survey-header-v2.styles.module.scss";
import Popper from "@mui/material/Popper";

type SurveyHeaderV2Props = {
  onSetupClick: () => void;
  surveyId: string;
  vendors: {
    id: string;
    imageLoc: string;
    name: string;
  }[];
  reloadSurveyData: () => void;
};

export const SurveyHeaderV2Component: React.FC<SurveyHeaderV2Props> = ({
  onSetupClick,
  surveyId,
  vendors,
  reloadSurveyData,
}) => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId as UUID);
  const [isError, setIsError] = useState(false);
  const [isQuickStartModelOpen, setIsQuickStartModelOpen] = useState(false);
  const [isTeamManagementDrawerOpen, setIsTeamManagementDrawerOpen] = useState(false);
  const [isExportPopperOpen, setIsExportPopperOpen] = useState(false);
  const captureEventsV2 = useCaptureEventsV2();
  const anchorRef = useRef<HTMLButtonElement>(null);

  const clientEvents = [
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_CREATING_QUESTIONNAIRE_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_SENDING_QUESTIONNAIRE_CLICKED,
    ClientEvent.INITIATIVE_SURVEY_QUICKSTART_VIEWING_RESPONSE_CLICKED,
  ];

  const quickStartTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Creating the questionnaire", content: <CreatingTheQuestionnaireComponent /> },
    { label: "Sending the questionnaire", content: <SendingTheQuestionnaireComponent /> },
    { label: "Viewing responses", content: <ViewingResponsesComponent /> },
  ];

  const handleQuickStartClose = () => {
    captureEventsV2([
      {
        eventType: ClientEvent.INITIATIVE_SURVEY_QUICKSTART_MODAL_CLOSED,
        metaData: { initiativeId },
      },
    ]);
    setIsQuickStartModelOpen(false);
  };

  const handleQuickStartBtnClick = () => {
    captureEventsV2([
      {
        eventType: ClientEvent.INITIATIVE_SURVEY_QUICKSTART_MODAL_OPENED,
        metaData: { initiativeId },
      },
    ]);
    setIsQuickStartModelOpen(true);
  };

  const getSignedUrl = async (url: string) => {
    try {
      const response = await getAsyncRequest(url);
      return response.data.data.signedUrl;
    } catch (error) {
      setIsError(true);
    }
  };

  const handleSurveyExportClick = async () => {
    setIsExportPopperOpen(false);
    const url = `api/v2/initiatives/${initiativeId}/survey/${surveyId}/export-survey`;
    const signedUrl = await getSignedUrl(url);
    captureEventsV2([{ eventType: ClientEvent.SURVEY_EXPORTED, metaData: { initiativeId, surveyId } }]);
    window.open(signedUrl, "_self");
  };

  const handleSurveyResponseExportClick = async () => {
    setIsExportPopperOpen(false);
    const url = `api/v2/initiatives/${initiativeId}/survey/${surveyId}/export-responses`;
    const signedUrl = await getSignedUrl(url);
    captureEventsV2([{ eventType: ClientEvent.SURVEY_RESPONSES_EXPORTED, metaData: { initiativeId, surveyId } }]);
    window.open(signedUrl, "_self");
  };

  const handlePreviewButtonClick = () => {
    captureEventsV2([{ eventType: ClientEvent.SURVEY_PREVIEW_CLICKED, metaData: { initiativeId, surveyId } }]);
    window.open(`${window.location.pathname}/preview/overview`, "_blank", "noopener");
  };

  const handleEventCapture = (tabIndex: number) => {
    captureEventsV2([
      {
        eventType: clientEvents[tabIndex],
        metaData: { initiativeId },
      },
    ]);
  };

  const handleExportBtnClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (hasLoadedAccess && role === InitUserRole.OWNER) {
      setIsExportPopperOpen((prev) => !prev);
    } else {
      await handleSurveyResponseExportClick();
    }
  };

  return (
    <Fragment>
      <div className={styles.surveyHeader}>
        <div className={classNames(styles.surveyHeaderTitle)}>
          <div className={styles.headingContainer}>
            <TypographyComponent color={"carbon"} boldness={"medium"} styling={"h5"}>
              {t("Questionnaire")}
            </TypographyComponent>
            <MiniButton onClick={handleQuickStartBtnClick} rootClassName={styles.howItWorksBtn}>
              <QuestionMarkIcon fillPath={colorPalette.interactions.blueBerry.hex} />
              {t("How it works")}
            </MiniButton>
          </div>
          <div className={styles.iconBtnContainer}>
            {hasLoadedAccess && role === InitUserRole.OWNER && (
              <Fragment>
                <ToolbarButtonComponent
                  onClick={() => setIsTeamManagementDrawerOpen(true)}
                  text={t("Send")}
                  icon={<SendIcon />}
                  rootClassName={"gx-manage-recipients-button"}
                  tooltipProps={{
                    PopperProps: { modifiers: { offset: { offset: "0, 12px" } } as never },
                    placement: "top",
                    title: t("Send questionnaire"),
                  }}
                />
                <ToolbarButtonComponent
                  tooltipProps={{
                    PopperProps: { modifiers: { offset: { offset: "0, 12px" } } as never },
                    placement: "top",
                    title: t("Edit questionnaire"),
                  }}
                  onClick={onSetupClick}
                  text={t("Edit")}
                  icon={<EditIcon />}
                />
              </Fragment>
            )}

            <ToolbarButtonComponent
              tooltipProps={{
                PopperProps: { modifiers: { offset: { offset: "0, 12px" } } as never },
                placement: "top",
                title: t("Preview questionnaire"),
              }}
              onClick={handlePreviewButtonClick}
              text={t("Preview")}
              icon={<PreviewIcon />}
            />

            <span ref={anchorRef}>
              <ToolbarButtonComponent
                tooltipProps={{
                  title:
                    role === InitUserRole.OWNER
                      ? t("Export questionnaire or vendor responses")
                      : t("Export questionnaire responses"),
                  placement: "left",
                }}
                isActive={isExportPopperOpen}
                onClick={handleExportBtnClick}
                text={t("Export")}
                icon={<ExportIcon />}
              />
            </span>
          </div>
        </div>
      </div>

      <QuickStartPopperComponent
        title={t("Vendor questionnaire")}
        isOpen={isQuickStartModelOpen}
        onClose={handleQuickStartClose}
        tabsData={quickStartTabsData}
        onTabClick={handleEventCapture}
      />

      <ClickAwayListener onClickAway={() => setIsExportPopperOpen(false)}>
        <Popper
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [-72, 1],
              },
            },
          ]}
          open={isExportPopperOpen}
          className={styles.popper}
          anchorEl={anchorRef.current}
        >
          <button className={styles.popperContent} onClick={handleSurveyExportClick}>
            <TypographyComponent styling={"p3"} boldness={"medium"} color={"carbon"}>
              {t("Export questionnaire")}
            </TypographyComponent>
          </button>
          <button className={styles.popperContent} onClick={handleSurveyResponseExportClick}>
            <TypographyComponent styling={"p3"} boldness={"medium"} color={"carbon"}>
              {t("Export responses")}
            </TypographyComponent>
          </button>
        </Popper>
      </ClickAwayListener>

      <SurveyTeamManagementV2
        isOpen={isTeamManagementDrawerOpen}
        onClose={() => {
          setIsTeamManagementDrawerOpen(false);
        }}
        vendors={vendors}
        initiativeId={initiativeId}
        surveyId={surveyId}
        reloadSurveyData={reloadSurveyData}
      />
      <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
    </Fragment>
  );
};
