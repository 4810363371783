import Popper from "@mui/material/Popper";
import { ClickAwayListener, PopperPlacementType, PopperProps } from "@mui/material";
import React, { FC, useState } from "react";
import styles from "./requirement-popper.styles.module.scss";
import classNames from "classnames";
import { IconButton, ProductLogo, TypographyComponent } from "gx-npm-ui";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowRoundedTipWithBorder, CloseIcon } from "gx-npm-icons";
import { RequirementPopperContent } from "../../survey-table.constants";

type RequirementPopperProps = {
  onClose: () => void;
  open: boolean;
  anchor: React.ReactNode;
  requirementPopperContent: RequirementPopperContent | null;
};

export const RequirementPopperComponent: FC<RequirementPopperProps> = ({
  onClose,
  open,
  anchor,
  requirementPopperContent,
}) => {
  const [currentPlacement, setCurrentPlacement] = useState<PopperPlacementType>("bottom");

  return (
    <ClickAwayListener onClickAway={onClose}>
      <span>
        <Popper
          open={open}
          anchorEl={anchor as PopperProps["anchorEl"]}
          placement={currentPlacement}
          className={styles.root}
          onClick={(e) => e.stopPropagation()}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 12],
              },
            },
            {
              name: "eventListeners",
              enabled: true,
              options: {
                scroll: true,
                resize: true,
              },
              fn({ state }) {
                setCurrentPlacement((prevState) =>
                  currentPlacement !== state.placement ? state.placement : prevState
                );
              },
            },
            {
              name: "flip",
              options: {
                fallbackPlacements: ["top", "bottom", "left", "right"],
              },
            },
          ]}
        >
          <div className={classNames(styles.wrapper)}>
            <section className={styles.header}>
              <IconButton
                ariaLabel={"close"}
                onClick={onClose}
                className={styles.closeBtnWrapper}
                hoverFill={colorPalette.neutrals.iron.hex}
                hoverBackgroundColor={colorPalette.basic.white.hex}
              >
                <CloseIcon />
              </IconButton>
            </section>
            {requirementPopperContent && (
              <div className={styles.requirementPopperRoot}>
                <div className={styles.productInfo}>
                  <ProductLogo
                    name={requirementPopperContent.product.name}
                    imageWidth={"24px"}
                    imageHeight={"24px"}
                    logo={requirementPopperContent.product.imageLoc}
                  />
                  <TypographyComponent styling={"p4"} boldness={"medium"} rootClassName={styles.productName}>
                    {requirementPopperContent.product.name}
                  </TypographyComponent>
                </div>
                <div className={styles.requirementName}>
                  <TypographyComponent styling={"p3"} boldness={"medium"}>
                    {requirementPopperContent.requirement.name}
                  </TypographyComponent>
                </div>
                <div className={styles.requirementResponse}>
                  <TypographyComponent styling={"p3"} boldness={"medium"}>
                    {requirementPopperContent.requirementResponse}
                  </TypographyComponent>
                </div>
                <div
                  className={classNames(
                    styles.requirementComment,
                    requirementPopperContent.uploadedRequirement &&
                      requirementPopperContent.uploadedRequirement.comment &&
                      styles.requirementCommentSpacing
                  )}
                >
                  <TypographyComponent styling={"p4"} boldness={"regular"} color={"coal"}>
                    {(requirementPopperContent.uploadedRequirement &&
                      requirementPopperContent.uploadedRequirement.comment) ||
                      "No additional comments."}
                  </TypographyComponent>
                </div>
              </div>
            )}
          </div>
          <div className={styles.bottomShadow} />
          <span
            className={classNames(styles.arrow, {
              [styles.arrowUpward]: currentPlacement === "bottom",
              [styles.arrowDownward]: currentPlacement === "top",
              [styles.arrowLeft]: currentPlacement === "right",
              [styles.arrowRight]: currentPlacement === "left",
            })}
          >
            {<ArrowRoundedTipWithBorder />}
          </span>
        </Popper>
      </span>
    </ClickAwayListener>
  );
};
