import React, { useContext } from "react";
import classNames from "classnames";
import { ScoringMode } from "gx-npm-lib";

import TableCarouselHeaderRowCol from "../../../../../../ui/table-carousel-v2/header/table-carousel-header-row-col.component";
import ScorecardReqCatItems from "./scorecard-req-cat-items/scorecard-req-cat-items.component";
import { Requirement } from "../../../../../../app.types";
import styles from "./scorecard-requirements.styles.module.scss";
import ScorecardReqCat from "./scorecard-req-cat/scorecard-req-cat.component";
import { CarouselContext } from "../../carousel.context";

type ScorecardRequirementsProps = {
  requirements: Requirement[];
  onAvatarClick: () => void;
  isOwner: boolean;
  scoringMode: ScoringMode;
};

const ScorecardRequirementsComponent = ({
  requirements,
  onAvatarClick,
  isOwner,
  scoringMode,
}: ScorecardRequirementsProps) => {
  const { visibleProducts } = useContext(CarouselContext);
  const displayedProductCount = visibleProducts.length;

  return (
    <>
      {requirements.map((requirement, index, arr) => {
        const { id, items } = requirement;

        return (
          <TableCarouselHeaderRowCol
            key={id.toString() || index.toString()}
            rootClass={classNames(
              "gx-req-root-head-row",
              styles.reqRootHeadRow,
              styles.reqScoringAssignment,
              arr.length - 1 === index && "gx-last-req-root"
            )}
          >
            <ScorecardReqCat
              reqCat={requirement}
              reqCatCount={requirements.length}
              index={index}
              isOwner={isOwner}
              scoringMode={scoringMode}
              onAvatarClick={onAvatarClick}
              displayedProductsCount={displayedProductCount}
            />
            <ScorecardReqCatItems index={index} reqItems={items} displayProductsCount={displayedProductCount} />
            {index === 0 && displayedProductCount === 0 && <div className={styles.rightBorderTop} />}
            {index === requirements.length - 1 && displayedProductCount === 0 && (
              <div className={styles.rightBorderBottom} />
            )}
          </TableCarouselHeaderRowCol>
        );
      })}
    </>
  );
};

export default ScorecardRequirementsComponent;
