import {
  Category,
  LegendColors,
  Product,
  Requirement,
  RequirementPopperContent,
  ResponseOption,
} from "../../survey-table.constants";
import { RowSet } from "../../../../../ui/table/table.constants";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import styles from "./create-body-rows.styles.module.scss";
import classNames from "classnames";
import ChartComponent from "../../components/chart/chart.component";
import React from "react";
import { InitProdState } from "gx-npm-lib";
import { t } from "i18next";

type CreateBodyRowsProps = {
  products: Product[];
  categories: Category[];
  responseOptions: ResponseOption[];
  onCellClick: (event: React.MouseEvent<HTMLDivElement>, cellData: RequirementPopperContent) => void;
};

export const createBodyRows = ({
  products,
  categories,
  responseOptions,
  onCellClick,
}: CreateBodyRowsProps): RowSet[] => {
  const priorityStyleMap: Record<number, string> = {
    1: styles.priorityOne,
    2: styles.priorityTwo,
    3: styles.priorityThree,
  };
  const priorityNameMap: Record<number, { name: string; tooltipText: string }> = Object.freeze({
    1: {
      name: t("LOW"),
      tooltipText: t("Low Priority"),
    },
    2: {
      name: t("MED"),
      tooltipText: t("Medium Priority"),
    },
    3: {
      name: t("HIGH"),
      tooltipText: t("High Priority"),
    },
  });

  const getStyleForPriority = (priority: number): string => priorityStyleMap[priority] ?? "";

  const renderRequirementRow = (category: Category, requirement: Requirement) => {
    const requirementCells = products.map((product, i) => {
      const uploadedRequirement = product.categories?.[category.id]?.requirements?.[requirement.id];
      const requirementResponse =
        responseOptions.find((response) => response.id === uploadedRequirement?.responseOptionId)?.name || "-";

      return (
        <div
          role={"button"}
          className={classNames(
            styles.requirementResponse,
            styles.requirementResponseWithContent,
            product.productState === InitProdState.SCREENED_OUT && styles.screenedOut
          )}
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={(event) => {
            document.body.click(); // close all poppers

            onCellClick(event, {
              uploadedRequirement,
              requirement,
              product,
              requirementResponse,
            });
          }}
          key={`product-${i}`}
        >
          <TypographyComponent
            color="coal"
            styling="p3"
            boldness="medium"
            rootClassName={classNames(requirementResponse === "-" && styles.dash)}
          >
            {requirementResponse}
          </TypographyComponent>
        </div>
      );
    });

    return [
      <div className={styles.requirementDetails} key={requirement.id}>
        <div className={styles.priorityContainer}>
          <div className={classNames(getStyleForPriority(requirement.priority))}>
            <TooltipV2
              PopperProps={{ modifiers: { offset: { offset: "-12, 10" } } }}
              placement={"left-start"}
              title={priorityNameMap[requirement.priority].tooltipText}
            >
              <span>
                <TypographyComponent rootClassName={styles.priority} styling={"p5"} boldness={"regular"}>
                  {priorityNameMap[requirement.priority].name}
                </TypographyComponent>
              </span>
            </TooltipV2>
          </div>
        </div>
        <TypographyComponent rootClassName={styles.requirementName} styling="p2" boldness="regular">
          {requirement.name}
        </TypographyComponent>
      </div>,
      ...requirementCells,
    ];
  };

  const renderCategoryTitle = (category: Category) => (
    <TypographyComponent styling="p2" boldness="medium">
      {category.name}
      <TypographyComponent
        color={"iron"}
        rootClassName={styles.requirementsLength}
        styling="p2"
        boldness="regular"
        element="span"
      >
        ({category.requirements.length})
      </TypographyComponent>
    </TypographyComponent>
  );

  const renderChartRow = (category: Category) => {
    const chartCells = products.map((product) => {
      const optionCounts = responseOptions.map((option, index) => {
        const count = Object.values(category.requirements).filter(
          (req) => product.categories[category.id]?.requirements[req.id]?.responseOptionId === option.id
        ).length;

        return {
          value: count,
          label: option.name,
          sliceIndex: index,
          responseOptionId: option.id,
        };
      });

      const total = optionCounts.reduce((sum, option) => sum + option.value, 0);

      const chartData =
        total === 0
          ? responseOptions.map((option, index) => ({
              value: 1,
              label: option.name,
              sliceIndex: index,
              responseOptionId: option.id,
            }))
          : optionCounts;

      return (
        <div
          key={`chart-${product.id}`}
          className={classNames(
            styles.chartColumn,
            product.productState === InitProdState.SCREENED_OUT && styles.screenedOut
          )}
        >
          <ChartComponent product={product} category={category} data={chartData} total={total} />
        </div>
      );
    });

    const responseLegends = responseOptions.map((option, index) => (
      <div key={option.id} className={styles.responseOptionRow}>
        <div className={styles.responseOption} style={{ backgroundColor: LegendColors[index] }} />
        <TypographyComponent styling={"p3"} boldness={"medium"} color={"coal"}>
          {option.name}
        </TypographyComponent>
      </div>
    ));

    return [
      <div key="chart-label" className={styles.responseLegends}>
        {responseLegends}
      </div>,
      ...chartCells,
    ];
  };

  return categories.map((category) => ({
    title: renderCategoryTitle(category),
    rows: [
      renderChartRow(category),
      ...category.requirements.map((requirement) => renderRequirementRow(category, requirement)),
    ],
  }));
};
