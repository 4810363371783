import React, { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { InitProdState, ScoringLevel, ScoringMode, UUID } from "gx-npm-lib";

import { Requirement } from "../../../../../../app.types";
import ScorecardReqCat from "../scorecard-requirements/scorecard-req-cat/scorecard-req-cat.component";
import ProductScoreCarouselColumn from "../scorecard-product-scores/product-score-carousel-column/product-score-carousel-column";
import { CarouselContext } from "../../carousel.context";
import styles from "./scorecard-docked-req-cat-prod-scores.styles.module.scss";
import useResponsiveColumns from "../../../../../../ui/tableCarousel/use-responsive-columns";
import { scoresRefCallback } from "./scores-ref-callback.lib";

type ScorecardDockedCategoryType = {
  width: number;
  requirements: Requirement[];
  isOwner: boolean;
  isPopperOpen: boolean;
  scoringMode: ScoringMode;
  scoringLevel: ScoringLevel;
  onAvatarClick?: () => void;
  onOpenPopper: () => void;
};

const ScorecardDockedReqCatProdScores = ({
  width = 0,
  requirements = [],
  isOwner,
  isPopperOpen,
  scoringMode,
  scoringLevel,
  onAvatarClick,
  onOpenPopper,
}: ScorecardDockedCategoryType) => {
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { dockedReqCatIdx, currentPos, showStickyHeader, visibleProducts } = useContext(CarouselContext);
  const [hasAwardedProduct, setHasAwardedProduct] = useState<boolean>(false);
  const [scoresXPos, setScoresXPos] = useState<number>(0);

  const maxProdCols = useResponsiveColumns();

  const visibleProductsCount = visibleProducts.length;

  const memoizedScoresRefCallback = useCallback(
    (node: HTMLDivElement | null) => {
      const hasReachedEnd = visibleProductsCount - maxProdCols - currentPos + 1 === maxProdCols;
      const scoresXPosCalculated = scoresRefCallback({
        scoresContainerWidth: node?.offsetWidth || 0,
        visibleProductsCount,
        currentPos,
        hasAwardedProduct,
        maxProdCols,
        hasReachedEnd,
      });
      setScoresXPos(scoresXPosCalculated);
    },
    [visibleProductsCount, maxProdCols, currentPos, hasAwardedProduct]
  );

  const reqCat = requirements[dockedReqCatIdx];
  const reqCatCount = requirements.length;

  useEffect(() => {
    const awardedProduct = visibleProducts.find((product) => product.state === InitProdState.AWARDED);
    setHasAwardedProduct(awardedProduct !== undefined);
  }, [visibleProducts]);

  if (!visibleProducts || visibleProducts.length === 0 || !reqCat) {
    return null;
  }

  const isCurrentPositionOdd = currentPos % 2 !== 0;
  const isMaxColsOdd = maxProdCols % 2 !== 0;

  const showDarkBG = isMaxColsOdd ? isCurrentPositionOdd : !isCurrentPositionOdd;
  const showRightBumper = visibleProductsCount > maxProdCols;

  return (
    <div className={classNames(styles.dockedCategory, !showStickyHeader && styles.hidden)}>
      <div
        style={{
          width: `${width}px`,
        }}
        className={classNames(
          styles.rowWrapper,
          showDarkBG && styles.showDarkBG,
          showRightBumper && styles.showRightBumper
        )}
      >
        <div className={styles.requirementName}>
          <ScorecardReqCat
            reqCat={reqCat}
            reqCatCount={reqCatCount}
            displayedProductsCount={visibleProductsCount}
            index={dockedReqCatIdx}
            isOwner={isOwner}
            onAvatarClick={onAvatarClick}
            scoringMode={scoringMode}
            isWithinDockedRow={true}
          />
        </div>
        <div
          ref={memoizedScoresRefCallback}
          style={{ transform: `translateX(-${scoresXPos}px)` }}
          className={styles.displayedProducts}
        >
          {visibleProducts.length &&
            visibleProducts.map((product) => (
              <ProductScoreCarouselColumn
                key={product.id}
                prodInitId={product.id as UUID}
                prodName={product.name}
                prodState={product.state as InitProdState}
                prodImageLoc={product.imageLoc}
                catId={reqCat.id}
                catName={reqCat.name}
                maxProdCols={maxProdCols}
                displayedProductsCount={visibleProductsCount}
                initId={initiativeId}
                isRequirementExpanded={true}
                isDocked={true}
                hasAwardedProductInProducts={hasAwardedProduct}
                onOpenPopper={onOpenPopper}
                isCarouselPopperOpen={isPopperOpen}
                scoringLevel={scoringLevel}
                scoreCategory={product.reqCategoryScoreMap[reqCat.id].catScore}
                scoreCategoryDisplay={product.reqCategoryScoreMap[reqCat.id].catScoreDisplay}
                scoreMapRequirementItems={{}}
              />
            ))}
        </div>
        {showRightBumper && <div className={classNames(styles.rightBumper, showDarkBG && styles.showDarkBG)} />}
      </div>
    </div>
  );
};

export default ScorecardDockedReqCatProdScores;
