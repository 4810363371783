import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { InitProdState, ScorecardProgress } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";

import styles from "./scorecard-product-status.styles.module.scss";
import { Product } from "../../../../../../../app.types";
import { CarouselContext } from "../../../carousel.context";

const ScorecardProductStatus: FC = () => {
  const { t } = useTranslation();

  const { visibleProducts } = useContext(CarouselContext);

  const getProgressMessage = (progress: ScorecardProgress) => {
    let selectedMessage = t("Not started");
    if (progress === ScorecardProgress.COMPLETE) {
      selectedMessage = t("Complete");
    } else if (progress === ScorecardProgress.IN_PROGRESS) {
      selectedMessage = t("In progress");
    }
    return (
      <TypographyComponent boldness={"medium"} color={progress === ScorecardProgress.COMPLETE ? "darkPear" : "iron"}>
        {selectedMessage}
      </TypographyComponent>
    );
  };

  return (
    <>
      {visibleProducts.map((product: Product, index: number) => (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <td
          key={`vendor-status-${index}`}
          className={classNames(
            product.state === InitProdState.SCREENED_OUT && "gx-screened-out-bk-gnd",
            product.state === InitProdState.AWARDED ? "gx-awarded" : "gx-not-awarded"
          )}
        >
          <TypographyComponent
            styling={"h2"}
            element={"h5"}
            boldness={"medium"}
            color={product.state === InitProdState.SCREENED_OUT ? "iron" : "carbon"}
            rootClassName={classNames(
              styles.overallScoreDisplay,
              Math.floor(product.overallScore) === 0 &&
                product.progress === ScorecardProgress.NOT_STARTED &&
                styles.dashPlaceholder
            )}
          >
            {product.overallScoreDisplay}
          </TypographyComponent>
          <TypographyComponent styling={"p3"} boldness={"medium"}>
            {getProgressMessage(product.progress as ScorecardProgress)}
          </TypographyComponent>
        </td>
      ))}
    </>
  );
};

export default ScorecardProductStatus;
