import classNames from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BuySmartLogo } from "gx-npm-icons";
import { isValidDate } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { SelectionContext } from "../../../../selection.context";
import { DateTimeFormatOptions } from "../../../../selection.types";
import TeamMembersV2Component from "./team-members-v2/team-members-v2.component";
import DetailsChecklistSection from "./components/details-checklist-section.component";
import styles from "./initiative-details-card.styles.module.scss";

const displayDate = (date: string) => {
  let display = "";
  if (isValidDate(date)) {
    const day: DateTimeFormatOptions = "numeric";
    const month: DateTimeFormatOptions = "long";
    const timeZone = "UTC";
    const year: DateTimeFormatOptions = "numeric";
    const options = { day, month, timeZone, year };
    display = new Date(date).toLocaleString("en-us", options);
  }
  return display;
};
const InitiativeDetailsCard = () => {
  const { t } = useTranslation();
  const { title, createdDate, statusUpdateDate } = useContext(SelectionContext);
  const dateRange = `${displayDate(createdDate)} - ${displayDate(statusUpdateDate)}`;

  return (
    <div className={styles.detailsContainer}>
      <div className={styles.detailsHeader}>
        <TypographyComponent boldness={"medium"} rootClassName={styles.headerTitle} styling={"p4"}>
          {t("Evaluation details")}
        </TypographyComponent>
        <BuySmartLogo variant="white" width="92" height="18" />
      </div>
      <div className={classNames(styles.detailsContent)}>
        <TypographyComponent boldness={"medium"} rootClassName={styles.initTitle} styling={"p1"}>
          {title ? title : t("Untitled evaluation")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.initRange} styling={"p4"}>
          {dateRange}
        </TypographyComponent>
        <TeamMembersV2Component />
        <DetailsChecklistSection />
      </div>
    </div>
  );
};

export default InitiativeDetailsCard;
