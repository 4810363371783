import { ChecklistPhaseStatus } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import NotStartedTaskIcon from "../../../../../../../ui/icons/not-started-task.icon";
import CompletedTaskIcon from "../../../../../../../ui/icons/completed-task.icon";
import InProgressTaskIcon from "../../../../../../../ui/icons/in-progress-task.icon";
import styles from "./checklist-status-display.styles.module.scss";

type ChecklistStatusDisplayProps = { status: ChecklistPhaseStatus };
const ChecklistStatusDisplay = ({ status }: ChecklistStatusDisplayProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.statusContainer}>
      <TypographyComponent
        boldness="medium"
        color={status === ChecklistPhaseStatus.COMPLETE ? "darkPear" : "iron"}
        styling="p4"
      >
        {status === ChecklistPhaseStatus.NOT_STARTED && t("Not started")}
        {status === ChecklistPhaseStatus.IN_PROGRESS && t("In progress")}
        {status === ChecklistPhaseStatus.COMPLETE && t("Complete")}
      </TypographyComponent>
      <div>
        {status === ChecklistPhaseStatus.NOT_STARTED && <NotStartedTaskIcon />}
        {status === ChecklistPhaseStatus.IN_PROGRESS && <InProgressTaskIcon />}
        {status === ChecklistPhaseStatus.COMPLETE && <CompletedTaskIcon />}
      </div>
    </div>
  );
};

export default ChecklistStatusDisplay;
