import { makeStyles } from "@material-ui/styles";
import { InitUserRole, isValidResponse, useCaptureEventsV2, useUserInitAccess } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  Loader,
  SnackbarBanner,
} from "gx-npm-ui";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { ClientEvent } from "../../app.constants";
import { getRequest } from "../../context/actions/apiRequests";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import SurveyHeader from "./header/survey-header.component";
import PreSurveyStartView from "./pre-survey-start-view/pre-survey-start-view.component";
import styles from "./survey-tab.styles";
import { SurveyTableComponent } from "./survey-table/survey-table.component";
import { NoRequirementsErrorComponent } from "./no-requirements-error/no-requirements-error.component";
import NoRecipientsBannerComponent from "./no-recipients-banner/no-recipients-banner.component";
import { SurveyTableContextProvider } from "./survey-table/context/survey-table/survey-table.context";
import { FilterContextProvider } from "./survey-table/context/filter/filter.context";
import { GCOM_4381__responsesAndScoresControl } from "../../lib/feature-flags";
import { SurveyHeaderV2Component } from "./survey-header-v2/survey-header-v2.component";

const useStyles = makeStyles(styles);
const SurveyTab = () => {
  const classes = useStyles();
  const { initiativeId = "" } = useParams();
  const { t } = useTranslation();
  const [hasSurvey, setHasSurvey] = useState(false);
  const [surveyId, setSurveyId] = useState("");
  const [surveyVendors, setSurveyVendors] = useState([]);
  const [surveyRequestedDocuments, setSurveyRequestedDocuments] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const captureEvents = useCaptureEventsV2();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);
  const [hasRequirements, setHasRequirements] = useState(true);
  const [hasRecipients, setHasRecipients] = useState(true);
  const [categories, setCategories] = useState([]);
  const [responseOptions, setResponseOptions] = useState([]);

  const loadSurveyData = useCallback(async () => {
    const url = `api/v2/initiatives/${initiativeId}/survey-overview`;
    const response = await getRequest(url);

    if (isValidResponse(response)) {
      const payload = response.data?.data;
      setHasSurvey(!!payload.surveyId);
      setSurveyId(payload.surveyId);

      setHasRecipients((payload.products || []).find((vendor) => vendor.hasRecipients));
      setHasRequirements(payload.hasRequirements);
      setSurveyVendors(payload.products || []);
      setCategories(payload.categories || []);
      setResponseOptions(payload.responseOptions || []);
      setSurveyRequestedDocuments(payload.documents || []);
    } else {
      setIsError(true);
    }
  }, [initiativeId]);

  useEffect(() => {
    setShowHeader(hasSurvey);
  }, [hasSurvey]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setIsLoading(true);
    loadSurveyData().finally(() => setIsLoading(false));
  }, [initiativeId, loadSurveyData]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.INITIATIVE_SURVEY_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  useEffect(() => {
    if (!window.Intercom || !hasSurvey || surveyVendors.length === 0) {
      return;
    }
    if (JSON.parse(localStorage.getItem("hasViewedQuestionnaireTabAfterInEvaluation")) === true) {
      return;
    }
    window.Intercom("startTour", 483234);
    localStorage.setItem("hasViewedQuestionnaireTabAfterInEvaluation", true);
  }, [hasSurvey, surveyVendors]);

  const handleClick = () => {
    const url = surveyId
      ? `/s/evaluation/${initiativeId}/questionnaire/setup/${surveyId}`
      : `/s/evaluation/${initiativeId}/questionnaire/setup`;
    singleSpa.navigateToUrl(url);
  };

  return (
    <Fragment>
      {!hasRequirements && hasSurvey && (
        <NoRequirementsErrorComponent
          handleClick={handleClick}
          disabled={hasLoadedAccess && role !== InitUserRole.OWNER}
        />
      )}
      <TabPanelComponent>
        <Fragment>
          {showHeader && (
            <FeatureFlagBooleanContainer flagName={GCOM_4381__responsesAndScoresControl}>
              <FeatureFlagBooleanOn>
                <SurveyHeaderV2Component
                  onSetupClick={handleClick}
                  surveyId={surveyId}
                  initiativeId={initiativeId}
                  vendors={surveyVendors}
                  reloadSurveyData={loadSurveyData}
                />
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <SurveyHeader
                  isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
                  onSetupClick={handleClick}
                  surveyId={surveyId}
                  initiativeId={initiativeId}
                  vendors={surveyVendors}
                  reloadSurveyData={loadSurveyData}
                />
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>
          )}
          {isLoading && (
            <div className={classes.loadingScreen}>
              <Loader size={40} variant="indeterminate" />
            </div>
          )}
          {!isLoading && !hasSurvey && (
            <PreSurveyStartView
              btnText={t("SET UP QUESTIONNAIRE")}
              onButtonClick={handleClick}
              isDisabled={hasLoadedAccess && role !== InitUserRole.OWNER}
            />
          )}

          {!isLoading && hasSurvey && surveyVendors.length > 0 && !hasRecipients && <NoRecipientsBannerComponent />}
          {!isLoading && hasSurvey && (
            <SurveyTableContextProvider>
              <FilterContextProvider>
                <SurveyTableComponent
                  categories={categories}
                  responseOptions={responseOptions}
                  initiativeId={initiativeId}
                  surveyId={surveyId}
                  products={surveyVendors}
                  requestedDocuments={surveyRequestedDocuments}
                  disabled={hasLoadedAccess && role !== InitUserRole.OWNER}
                />
              </FilterContextProvider>
            </SurveyTableContextProvider>
          )}
          <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
        </Fragment>
      </TabPanelComponent>
    </Fragment>
  );
};

export default SurveyTab;
