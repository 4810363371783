import React, { FC } from "react";
import classNames from "classnames";
import { TooltipV2, TypographyComponent } from "gx-npm-ui";
import styles from "./toolbar-button.styles.module.scss";
import { TooltipProps } from "@mui/material";

type ToolbarButtonProps = {
  onClick: (event: React.MouseEvent) => void;
  text: string;
  icon: React.ReactElement;
  isActive?: boolean;
  rootClassName?: string;
  disabled?: boolean;
  tooltipProps?: Partial<TooltipProps>;
};

export const ToolbarButtonComponent: FC<ToolbarButtonProps> = ({
  onClick,
  text,
  isActive,
  icon,
  rootClassName,
  disabled = false,
  tooltipProps = { title: "" },
}) => (
  <TooltipV2 title={tooltipProps.title} {...tooltipProps}>
    <span>
      <button
        onClick={onClick}
        className={classNames(
          {
            [styles.iconBtn]: true,
            ["gx-manage-recipients-button"]: true,
            [styles.active]: isActive,
          },
          rootClassName
        )}
        disabled={disabled}
      >
        <div className={styles.iconContainer}>{icon}</div>
        <TypographyComponent rootClassName={styles.iconText} styling={"p4"} boldness={"medium"}>
          {text}
        </TypographyComponent>
      </button>
    </span>
  </TooltipV2>
);
