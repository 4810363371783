import React, { useContext, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  STICKY_HEADER_IGNORE_CLASS,
  STICKY_HEADER_WRAPPER_CLASS,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { DeleteTrashcanIcon, EditPencilIcon } from "gx-npm-icons";
import ExclamationErrorIcon from "./exclamation-error.icon";
import { EvaluationStateContext } from "../../../../context";
import { operations } from "../../../../context/actions/operationTypes";
import { actionRequirementsTransaction } from "../../../../context/actions/requirementsActions";
import { Fade } from "../../../../ui/dragAndDropList";
import { ListHeader } from "../../../../ui/dragAndDropList/header";
import ListHeaderExpandButtonV2 from "../../../../ui/dragAndDropList/header/list-header-expand-button-v2";
import { RequirementsTextField } from "../components/textFields";
import { requirementsListHeaderStyles as styles } from "./styles";
import DeleteReqDetailsDialog from "../../dialogs/delete-req-details-dialog/delete-req-details-dialog.component";
import { useTranslation } from "react-i18next";
import EditCategoryWeightSlideIn from "../components/categoryWeightSlideIn/category-weight-slidein.component";
import { GCOM_280_GCOM_4449__fixCategoryWeight } from "../../../../lib/feature-flags";

const durationMsExpandCollapse = 300;
const durationMsFadeOut = 500;

const propTypes = {
  autoFocus: PropTypes.bool,
  expand: PropTypes.bool,
  index: PropTypes.number,
  initiativeId: PropTypes.string,
  isImportedCat: PropTypes.bool,
  isValidWeight: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  setCategoryAdded: PropTypes.func,
  setDeleteKey: PropTypes.func,
  setDeletedKey: PropTypes.func,
  setExpand: PropTypes.func,
};
const useStyles = makeStyles(() => styles);
const RequirementListHeader = ({
  autoFocus = false,
  expand = false,
  index = NaN,
  initiativeId = "",
  isImportedCat = false,
  isValidWeight = true,
  isViewOnly = false,
  setCategoryAdded = (_isAdded) => {},
  setDeleteKey = (_id) => {},
  setDeletedKey = (_id) => {},
  setExpand = (_isExpanded) => {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, dispatch] = useContext(EvaluationStateContext);

  const [fadeOut, setFadeOut] = useState(false);
  const [fadeOutKey, setFadeOutKey] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const isGCOM4449FixCategoryWeightOn = useFeatureFlag(GCOM_280_GCOM_4449__fixCategoryWeight);

  const handleAutoSave = (key, value) => {
    const data = {
      key,
      initiativeId,
      operation: operations.parentEdit,
      parentId: state.requirements.list[index]?.id,
      parentIndex: index,
      value,
    };
    actionRequirementsTransaction(state, dispatch, data);
  };

  const handleDelete = () => {
    if (fadeOutKey === state.requirements.list?.[index]?.id) {
      const data = {
        initiativeId,
        operation: operations.parentDelete,
        parentId: state.requirements.list[index]?.id,
        parentIndex: index,
      };
      actionRequirementsTransaction(state, dispatch, data);
      setDeletedKey(state.requirements.list?.[index]?.id);
    }
    setFadeOut(false);
  };

  const handleDeleteClosed = (confirmedDelete = false) => {
    const deleteKeyValue = state.requirements.list?.[index]?.id || "";
    if (confirmedDelete && deleteKeyValue) {
      setFadeOutKey(deleteKeyValue);
      const fadeTimeout = expand ? durationMsExpandCollapse : 0;
      const fadeTimer = setTimeout(() => {
        setDeleteKey(deleteKeyValue);
        setFadeOut(true);
        clearTimeout(fadeTimer);
      }, fadeTimeout);
      setExpand(false);
    }
    setIsDeleteDialogOpen(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const openEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  return (
    <Fade
      duration={durationMsFadeOut}
      fadeIn={false}
      fadeOut={!!fadeOut}
      onExit={handleDelete}
      rootClassName={classNames(
        classes.fadeWrapper,
        STICKY_HEADER_WRAPPER_CLASS,
        !expand && STICKY_HEADER_IGNORE_CLASS
      )}
    >
      <ListHeader
        expand={expand}
        index={index}
        rootClassName={classNames(
          "gx-dnd-req-cat-header",
          isViewOnly && "gx-read-only",
          isImportedCat && "gx-imported-cat"
        )}
        setExpand={setExpand}
      >
        <div className={classNames(classes.flexParent, classes.headerRoot)}>
          {state.requirements.list?.[index]?.isModified && <div className="gx-item-modified" />}
          <div className="gx-spacer" />
          <ListHeaderExpandButtonV2 expand={expand} setExpand={setExpand} />
          <RequirementsTextField
            autoFocus={autoFocus}
            handleAutoSave={(key, value) => handleAutoSave(key, value)}
            isViewOnly={isViewOnly}
            name="name"
            onBlur={() => setCategoryAdded(false)}
            placeholder={t("Enter category name")}
            rootClassName={"gx-header-title-field"}
            value={state.requirements.list?.[index]?.name}
          />
          <div className={classes.reqWeightEdit}>
            {isGCOM4449FixCategoryWeightOn && !isValidWeight && (
              <TooltipV2
                PopperProps={{ modifiers: { offset: { offset: "0px, 12px" } } }}
                title={t("Category weights must equal 100%")}
                placement="top"
              >
                <div>
                  <div aria-label={t("invalid weight")} className={"gx-req-cat-invalid-weight-iconGCOM4449"}>
                    <ExclamationErrorIcon />
                  </div>
                </div>
              </TooltipV2>
            )}
            <TypographyComponent
              boldness="medium"
              color={isGCOM4449FixCategoryWeightOn && !isValidWeight ? "poisonCherry" : "carbon"}
              styling="p1"
              rootClassName={"gx-req-cat-weight-label"}
            >
              {state.requirements.list?.[index]?.weight}%
            </TypographyComponent>
            <TooltipV2
              PopperProps={{ modifiers: { offset: { offset: "0, 12px" } } }}
              title={
                isViewOnly
                  ? t("This action is only available to evaluation owners and contributors.")
                  : t("Edit category weights")
              }
              placement="top"
            >
              <div>
                <IconButton
                  className={classNames("gx-req-cat-weight-icon", index === 0 && "gx-first-req-cat-weight-icon")}
                  disabled={isViewOnly}
                  onClick={() => openEditDialog()}
                >
                  <EditPencilIcon />
                </IconButton>
              </div>
            </TooltipV2>
          </div>
          {!isViewOnly && (
            <div className="gx-req-cat-delete-icon">
              <TooltipV2
                placement="top"
                PopperProps={{ modifiers: { offset: { offset: "2px, 7px" } } }}
                title={t("Delete")}
              >
                <div>
                  <IconButton
                    hoverFill={"var(--color-status-poison-cherry)"}
                    onClick={handleOpenDeleteDialog}
                    transparentBackground={true}
                  >
                    <DeleteTrashcanIcon />
                  </IconButton>
                </div>
              </TooltipV2>
            </div>
          )}
        </div>
      </ListHeader>
      <DeleteReqDetailsDialog
        initId={initiativeId}
        isOpen={isDeleteDialogOpen}
        name={state.requirements.list?.[index]?.name}
        onClose={handleDeleteClosed}
        reqCatId={state.requirements.list?.[index]?.id}
        reqState={state.requirements.state}
      />
      <EditCategoryWeightSlideIn
        initiativeId={initiativeId}
        isOpen={isEditDialogOpen}
        onClick={() => setIsEditDialogOpen(false)}
      />
    </Fade>
  );
};

RequirementListHeader.propTypes = propTypes;
export { RequirementListHeader };
