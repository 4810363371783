import React from "react";
import classNames from "classnames";
import styles from "./text-link-v2.styles.module.scss";
import { ArrowMoreIcon } from "gx-npm-icons";

enum variantType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  INLINE = "inline",
}

export type TextLinkV2Props = {
  text: string;
  href: string;
  variant?: `${variantType}`;
  theme?: "light" | "dark";
  disabled?: boolean;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
  rootClassName?: string;
  target?: string;
};
const TextLinkV2 = ({
  text = "",
  href = "",
  variant = "primary",
  theme = "light",
  disabled = false,
  onClick = (_e) => null,
  rootClassName = "",
  target = "",
}: TextLinkV2Props) => {
  const variantClass = {
    [styles.primary]: variant === variantType.PRIMARY,
    [styles.secondary]: variant === variantType.SECONDARY,
    [styles.inline]: variant === variantType.INLINE,
  };

  return (
    <a
      className={classNames(
        styles.textLink,
        disabled && styles.disabled,
        variantClass,
        theme === "dark" && styles.dark,
        rootClassName
      )}
      href={href || undefined}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick(e);
        }
      }}
      {...(target ? { target } : {})}
      {...(target === "_blank" ? { rel: "noreferrer" } : {})}
    >
      <span className={styles.textWrapper}>{text}</span>
      {variant === variantType.SECONDARY && <ArrowMoreIcon />}
    </a>
  );
};

export default TextLinkV2;
