// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FSW4spiStEqo3_AKFQYA{padding-right:81px}.FSW4spiStEqo3_AKFQYA .uQOHt_7tmzAW5yi3y8yH{align-items:center;display:flex;gap:15px;padding-bottom:12px}.FSW4spiStEqo3_AKFQYA .YFToAy1l9gmifagABT4H{width:400px}.FSW4spiStEqo3_AKFQYA .y3vXjPq7sNdtzXYAMv5F{padding-left:12px;width:388px}.FSW4spiStEqo3_AKFQYA .y3vXjPq7sNdtzXYAMv5F .vymUBID0d4insvVlwvBa{margin-bottom:12px}.FSW4spiStEqo3_AKFQYA .y3vXjPq7sNdtzXYAMv5F .vymUBID0d4insvVlwvBa::marker{color:var(--color-white)}.FSW4spiStEqo3_AKFQYA .XUGRlBpV9txY4QLath2X{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px;margin-top:32px}.FSW4spiStEqo3_AKFQYA .L5WeapzGvttTMG6yFAbQ{margin-bottom:16px}.FSW4spiStEqo3_AKFQYA .Ngw9FfLTL5U5NmVPf9Sj{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-section/research-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  padding-right: 81px;\n\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    width: 400px;\n  }\n\n  & .list {\n    padding-left: 12px;\n    width: 388px;\n\n    & .listItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n    margin-top: 32px;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FSW4spiStEqo3_AKFQYA`,
	"header": `uQOHt_7tmzAW5yi3y8yH`,
	"description": `YFToAy1l9gmifagABT4H`,
	"list": `y3vXjPq7sNdtzXYAMv5F`,
	"listItem": `vymUBID0d4insvVlwvBa`,
	"divider": `XUGRlBpV9txY4QLath2X`,
	"learnMore": `L5WeapzGvttTMG6yFAbQ`,
	"linkText": `Ngw9FfLTL5U5NmVPf9Sj`
};
export default ___CSS_LOADER_EXPORT___;
