import { Product } from "../../survey-table.constants";
import { FC } from "react";
import { InitProdState } from "gx-npm-lib";
import { Switch, TypographyComponent } from "gx-npm-ui";
import styles from "./screened-out-products.styles.module.scss";
import { useTranslation } from "react-i18next";

type ScreenedOutProductsProps = {
  products: Product[];
  onScreenedOutToggle: (value: boolean) => void;
};

export const ScreenedOutProductsComponent: FC<ScreenedOutProductsProps> = ({ products, onScreenedOutToggle }) => {
  const { t } = useTranslation();

  const screenedOutProducts: Product[] = products.filter(
    (product) => product.productState === InitProdState.SCREENED_OUT
  );

  return (
    <div className={styles.root}>
      <TypographyComponent styling={"p3"} boldness={"regular"} color={"coal"}>
        <Switch disabled={screenedOutProducts.length === 0} handleSwitchToggle={onScreenedOutToggle} />
        {t("Show screened-out vendors")} ({screenedOutProducts.length})
      </TypographyComponent>
    </div>
  );
};
