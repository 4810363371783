import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./survey-table.styles.module.scss";
import { TableComponent } from "../../../ui/table";
import { createHeaderRows } from "./utils/create-header-rows/create-header-rows";
import { createFooterRows } from "./utils/create-footer-rows/create-footer-rows";
import { createBodyRows } from "./utils/create-body-rows/create-body-rows";
import { NoProductViewComponent } from "./components/no-product-view/no-product-view.component";
import { RequirementPopperComponent } from "./components/requirement-popper/requirement-popper.component";
import { ScreenedOutProductsComponent } from "./components/screened-out-products/screened-out-products.component";
import { useTranslation } from "react-i18next";
import {
  Category,
  Product,
  RequestedDocument,
  RequirementPopperContent,
  ResponseOption,
} from "./survey-table.constants";
import { SurveyTableContext } from "./context/survey-table/survey-table.context";
import { InitProdState } from "gx-npm-lib";
import { FiltersComponent } from "./filters/filters.component";

type SurveyTableProps = {
  disabled: boolean;
  initiativeId: string;
  surveyId: string;
  products: Product[];
  requestedDocuments: RequestedDocument[];
  categories: Category[];
  responseOptions: ResponseOption[];
};

export const SurveyTableComponent: FC<SurveyTableProps> = ({
  disabled,
  initiativeId,
  surveyId,
  products,
  categories,
  responseOptions,
  requestedDocuments,
}) => {
  const { t } = useTranslation();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [requirementPopperContent, setRequirementPopperContent] = useState<RequirementPopperContent | null>(null);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [hasAtLeastOneResponseShared, setHasAtLeastOneResponseShared] = useState(false);
  const {
    filteredCategories,
    surveyProducts,
    setSurveyCategories,
    setSurveyResponseOptions,
    setSurveyProducts,
    setFilteredCategories,
  } = useContext(SurveyTableContext);

  useEffect(() => {
    setHasAtLeastOneResponseShared(
      !!products.find((product) => product.categories && Object.keys(product.categories).length > 0)
    );
  }, [products]);

  useEffect(() => {
    setSurveyProducts(products.filter((product) => product.productState !== InitProdState.SCREENED_OUT));
    setSurveyCategories(categories);
    setSurveyResponseOptions(responseOptions);
    setFilteredCategories(categories);
  }, [
    categories,
    products,
    responseOptions,
    setFilteredCategories,
    setSurveyCategories,
    setSurveyProducts,
    setSurveyResponseOptions,
  ]);

  const onCellClick = useCallback((event, cellData: RequirementPopperContent) => {
    event.stopPropagation();
    anchorRef.current?.classList.remove(styles.cellActive);
    setRequirementPopperContent(cellData);
    anchorRef.current = event.currentTarget;
    event.currentTarget.classList.add(styles.cellActive);
    setIsPopperOpen(true);
  }, []);

  const handleClosePopper = () => {
    setIsPopperOpen(false);
    anchorRef.current?.classList.remove(styles.cellActive);
  };

  const handleScreenOutToggle = (toggleState: boolean) => {
    const filteredProducts = toggleState
      ? products
      : products.filter((product) => product.productState !== InitProdState.SCREENED_OUT);
    setSurveyProducts(filteredProducts);
  };

  const headerRows = useMemo(() => {
    return surveyProducts.length > 0 ? createHeaderRows({ products: surveyProducts, disabled, initiativeId, t }) : [];
  }, [surveyProducts, disabled, initiativeId, t]);

  const bodyRowsSets = useMemo(() => {
    return filteredCategories.length > 0
      ? createBodyRows({
          products: surveyProducts,
          categories: filteredCategories,
          responseOptions,
          onCellClick,
        })
      : [];
  }, [filteredCategories, surveyProducts, responseOptions, onCellClick]);

  const footerRowSet = useMemo(() => {
    return createFooterRows({ requestedDocuments, products: surveyProducts, surveyId, initiativeId, t });
  }, [requestedDocuments, surveyProducts, surveyId, initiativeId, t]);

  return (
    <div className={styles.root}>
      {products.length === 0 && <NoProductViewComponent />}
      <TableComponent
        headerRows={headerRows}
        bodyRowSets={bodyRowsSets}
        footerRowSet={footerRowSet}
        filters={products.length > 0 && hasAtLeastOneResponseShared ? <FiltersComponent /> : null}
      />
      <RequirementPopperComponent
        requirementPopperContent={requirementPopperContent}
        onClose={handleClosePopper}
        anchor={anchorRef.current}
        open={isPopperOpen}
      />
      <ScreenedOutProductsComponent onScreenedOutToggle={handleScreenOutToggle} products={products} />
    </div>
  );
};
