// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xt810cLNJYgtL1eOjIdp{background-color:var(--color-status-light-cherry);width:100%}.Xt810cLNJYgtL1eOjIdp .Zflva2V8QC5vXOOdoZWb{max-width:1512px;margin:0 auto;display:flex;height:100px;align-items:center;padding:0 56px}.Xt810cLNJYgtL1eOjIdp .Zflva2V8QC5vXOOdoZWb .GA_UsKs0ElxCKErpGzh9{max-width:600px;margin:0 16px;color:var(--color-status-poison-cherry)}.Xt810cLNJYgtL1eOjIdp .Zflva2V8QC5vXOOdoZWb .HcnMVK2mXIPya70v_5Hw{flex-grow:1;display:flex;flex-direction:row-reverse}`, "",{"version":3,"sources":["webpack://./src/sections/survey/no-requirements-error/no-requirements-error.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iDAAA,CACA,UAAA,CAEA,4CACE,gBAAA,CACA,aAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,kEACE,eAAA,CACA,aAAA,CACA,uCAAA,CAGF,kEACE,WAAA,CACA,YAAA,CACA,0BAAA","sourcesContent":[".root {\n  background-color: var(--color-status-light-cherry);\n  width: 100%;\n\n  .container {\n    max-width: 1512px;\n    margin: 0 auto;\n    display: flex;\n    height: 100px;\n    align-items: center;\n    padding: 0 56px;\n\n    .textContent {\n      max-width: 600px;\n      margin: 0 16px;\n      color: var(--color-status-poison-cherry);\n    }\n\n    .navButton {\n      flex-grow: 1;\n      display: flex;\n      flex-direction: row-reverse;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Xt810cLNJYgtL1eOjIdp`,
	"container": `Zflva2V8QC5vXOOdoZWb`,
	"textContent": `GA_UsKs0ElxCKErpGzh9`,
	"navButton": `HcnMVK2mXIPya70v_5Hw`
};
export default ___CSS_LOADER_EXPORT___;
