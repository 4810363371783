import { getAsyncRequest, InitProdState, InitStatus, useCaptureEventsV2, UUID } from "gx-npm-lib";
import { SnackbarBanner } from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { ClientEvent } from "../../app.constants";
import { TabSectionLoader } from "../../ui/loader";
import AwardedAnimationV2Component from "./components/awarded-animation-v2/awarded-animation-v2.component";
import PreSelectionView from "./components/pre-selection-view/pre-selection-view.component";
import SelectionReport from "./components/selection-report/selection-report.component";
import { SelectionContext } from "./selection.context";
import { SelectionTabResponse } from "./selection.types";

type SelectionTabContentV2Props = { initiativeId: UUID };
const SelectionTabContentV2Component = ({ initiativeId = "" }: SelectionTabContentV2Props) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    products,
    status,
    showAnimation,
    setAwardedProduct,
    setAwardedProductOverview,
    setBudget,
    setChecklistPhases,
    setCreatedDate,
    setEvalComment,
    setInitiativeId,
    setObjectives,
    setProducts,
    setRequirementCategories,
    setRequirementItemCount,
    setTitle,
    setStatusUpdateDate,
    setStatus,
    setTeamMembers,
    setTemplateName,
    setTemplateDesc,
    setShowAnimation,
  } = useContext(SelectionContext);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setShowAnimation(JSON.parse(localStorage.getItem(`showAnimationOnSelection-${initiativeId}`) || "false"));
  }, [initiativeId, setShowAnimation]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    setIsLoading(true);
    (async () => {
      const url = `/api/v2/initiatives/${initiativeId}/selection`;
      const response: SelectionTabResponse = await getAsyncRequest(url);
      if (response.status === 200 && response.data?.data) {
        const {
          awardedProductOverview,
          budget,
          createdDate,
          checklistPhases,
          evalComment,
          title,
          objectives,
          products: payloadProducts,
          requirementCategories,
          requirementItemCount,
          statusUpdateDate,
          status: payloadStatus,
          teamMembers,
          templateName,
          templateDesc,
        } = response.data.data;

        const awardedProductObject = payloadProducts.find((product) => product.state === InitProdState.AWARDED) || {
          id: "",
          name: "",
          imageLoc: "",
          overallScoreDisplay: "",
          state: InitProdState.LISTED,
          scorecardCategories: [],
        };

        setAwardedProduct(awardedProductObject);
        setAwardedProductOverview(awardedProductOverview);
        setBudget(budget);
        setCreatedDate(createdDate);
        setChecklistPhases(checklistPhases);
        setEvalComment(evalComment);
        setInitiativeId(initiativeId);
        setObjectives(objectives);
        setProducts(payloadProducts);
        setRequirementCategories(requirementCategories);
        setRequirementItemCount(requirementItemCount);
        setStatus(payloadStatus);
        setStatusUpdateDate(statusUpdateDate);
        setTeamMembers(teamMembers);
        setTemplateName(templateName);
        setTemplateDesc(templateDesc);
        setTitle(title);
      } else {
        setHasError(true);
      }
      setIsLoading(false);
    })();
  }, [
    initiativeId,
    setAwardedProduct,
    setAwardedProductOverview,
    setBudget,
    setChecklistPhases,
    setCreatedDate,
    setEvalComment,
    setInitiativeId,
    setObjectives,
    setProducts,
    setRequirementCategories,
    setRequirementItemCount,
    setShowAnimation,
    setStatus,
    setStatusUpdateDate,
    setTeamMembers,
    setTemplateDesc,
    setTemplateName,
    setTitle,
  ]);

  useEffect(() => {
    captureEvents([{ eventType: ClientEvent.INITIATIVE_SELECTION_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  return (
    <Fragment>
      {showAnimation && <AwardedAnimationV2Component />}
      {!showAnimation && isLoading && <TabSectionLoader />}
      {!showAnimation && !isLoading && status !== InitStatus.SELECTED && (
        <PreSelectionView productsInEval={products.filter((p) => p.state === InitProdState.IN_EVALUATION)} />
      )}
      {!showAnimation && !isLoading && status === InitStatus.SELECTED && <SelectionReport />}
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={() => setHasError(false)} />
    </Fragment>
  );
};

export default SelectionTabContentV2Component;
