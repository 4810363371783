import { FormControlLabel, makeStyles, Radio as MuiRadioButton } from "@material-ui/core";
import classnames from "classnames";
import { ChangeEvent, ReactNode } from "react";
import styles from "./radio-button.styles";

const variantRadioButtonType = {
  darkTheme: "darkTheme",
  default: "",
};
const useStyles = makeStyles(() => styles);
type RadioButtonProps = {
  checked?: boolean;
  disabled?: boolean;
  inputProps?: object;
  label?: string | ReactNode;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  rootClassName?: string;
  value?: string;
  variant?: string;
};
const RadioButton = ({
  checked,
  disabled = false,
  inputProps = {},
  label = "",
  name = "",
  onChange = (_event) => {},
  onClick = () => {},
  rootClassName = "",
  value = "",
  variant = "",
  ...others
}: RadioButtonProps) => {
  const classes = useStyles();

  const renderRadioButton = () => {
    return (
      <MuiRadioButton
        {...others}
        aria-label="radio"
        checked={checked}
        className={classnames(
          "gx-radio",
          classes.radio,
          variant === variantRadioButtonType.darkTheme && "dark-theme-radio"
        )}
        disabled={disabled}
        inputProps={inputProps}
        name={name}
        onChange={onChange}
        onClick={onClick}
        value={value}
      />
    );
  };

  return (
    <div className={classnames("gx-radio-container", rootClassName && rootClassName)}>
      {label ? (
        <FormControlLabel
          classes={{
            label: classnames(
              "gx-radio-label",
              classes.label,
              variant === variantRadioButtonType.darkTheme && "dark-theme-radio"
            ),
          }}
          className={classes.label}
          control={renderRadioButton()}
          label={label}
        />
      ) : (
        renderRadioButton()
      )}
    </div>
  );
};

export { RadioButton, variantRadioButtonType };
