// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p4nIUeDugvdy6KS8yqAn{padding-bottom:50px;padding-top:75px;text-align:center}.ZPD2_iR84bvhqr7_JghZ{display:block;margin:0 auto;height:437px;width:349px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/awarded-animation-v2/awarded-animation-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,gBAAA,CACA,iBAAA,CAGF,sBACE,aAAA,CACA,aAAA,CACA,YAAA,CACA,WAAA","sourcesContent":[".text {\n  padding-bottom: 50px;\n  padding-top: 75px;\n  text-align: center;\n}\n\n.animator {\n  display: block;\n  margin: 0 auto;\n  height: 437px;\n  width: 349px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `p4nIUeDugvdy6KS8yqAn`,
	"animator": `ZPD2_iR84bvhqr7_JghZ`
};
export default ___CSS_LOADER_EXPORT___;
