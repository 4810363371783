import { Product, RequestedDocument } from "../../survey-table.constants";
import styles from "./create-footer-rows.styles.module.scss";
import { SnackbarBanner, TextLinkV2, TypographyComponent } from "gx-npm-ui";
import {
  FileTypeDocIcon,
  FileTypeDocxIcon,
  FileTypeJPegIcon,
  FileTypeMovIcon,
  FileTypeMP4Icon,
  FileTypePdfIcon,
  FileTypePNGIcon,
  FileTypePPTIcon,
  FileTypePPTxIcon,
  FileTypeTextIcon,
  FileTypeXlsIcon,
  FileTypeXlsxIcon,
} from "gx-npm-icons";
import { getAsyncRequest, InitProdState } from "gx-npm-lib";
import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type FooterProps = {
  requestedDocuments: RequestedDocument[];
  products: Product[];
  initiativeId: string;
  surveyId: string;
  t: (value: string) => string;
};

type DocumentCellProps = {
  product: Product;
  doc: RequestedDocument;
  surveyId: string;
  initiativeId: string;
};
const DocumentCell = ({ product, doc, initiativeId, surveyId }: DocumentCellProps) => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState(false);

  const handleClickDownload = async (initProductId: string, surveyProductDocId: string) => {
    const url = `/api/v2/initiatives/${initiativeId}/survey-documents/evaluator/${surveyId}/${initProductId}/documents/${surveyProductDocId}/download`;

    try {
      const response = await getAsyncRequest(url);
      if (response.status !== 200 || !response.data?.data?.signedUrl) {
        throw new Error("Failed to retrieve the signed URL");
      }
      window.open(response.data.data.signedUrl, "_blank", "noopener");
    } catch (error) {
      setHasError(true);
    }
  };

  const docFile = product?.files?.[doc.id]?.fileName;
  const isDocSkipped = product?.files?.[doc.id]?.isSkipped || false;
  let fileExt = "";
  if (docFile) {
    const fileParts = docFile.split(".");
    fileExt = fileParts[fileParts.length - 1].toLocaleLowerCase();
  }

  return (
    <div
      className={classNames(
        styles.documentContainer,
        product.productState === InitProdState.SCREENED_OUT && styles.screenedOut
      )}
    >
      {docFile && (
        <div className={styles.icon}>
          {fileExt === "doc" && <FileTypeDocIcon />}
          {fileExt === "docx" && <FileTypeDocxIcon />}
          {(fileExt === "jpeg" || fileExt === "jpg") && <FileTypeJPegIcon />}
          {fileExt === "mov" && <FileTypeMovIcon />}
          {fileExt === "mp4" && <FileTypeMP4Icon />}
          {fileExt === "pdf" && <FileTypePdfIcon />}
          {fileExt === "png" && <FileTypePNGIcon />}
          {fileExt === "ppt" && <FileTypePPTIcon />}
          {fileExt === "pptx" && <FileTypePPTxIcon />}
          {fileExt === "txt" && <FileTypeTextIcon />}
          {fileExt === "xls" && <FileTypeXlsIcon />}
          {fileExt === "xlsx" && <FileTypeXlsxIcon />}
        </div>
      )}
      {docFile ? (
        <div
          aria-label="download-document"
          tabIndex={0}
          role="button"
          onClick={() => handleClickDownload(product.id, doc.id)}
          onKeyDown={(event) => event.key === "Enter" && handleClickDownload(product.id, doc.id)}
        >
          <TypographyComponent color="coal" styling="p3" boldness="medium">
            <TextLinkV2
              rootClassName={styles.documentLink}
              text={docFile}
              href={""}
              target="_blank"
              variant={"secondary"}
            />
          </TypographyComponent>
        </div>
      ) : isDocSkipped ? (
        <TypographyComponent color="coal" styling="p3" boldness="regular">
          {t("Document skipped")}
        </TypographyComponent>
      ) : (
        "-"
      )}
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
    </div>
  );
};
export const createFooterRows = ({ requestedDocuments, products, surveyId, initiativeId, t }: FooterProps) => {
  if (requestedDocuments.length === 0) {
    return;
  }

  const firstColOfRow = (documentName: string) => {
    return (
      <div>
        <TypographyComponent styling={"p2"} boldness={"regular"}>
          {documentName}
        </TypographyComponent>
      </div>
    );
  };

  const renderFooterHeader = () => {
    return (
      <TypographyComponent styling={"p2"} boldness={"medium"}>
        {t("Requested Documents")}
      </TypographyComponent>
    );
  };

  return {
    title: renderFooterHeader(),
    rows: [
      ...requestedDocuments.map((doc) => [
        firstColOfRow(doc.requestedDocName),
        ...products.map((product) => (
          <DocumentCell
            key={`${product.id}-${doc.id}`}
            product={product}
            doc={doc}
            surveyId={surveyId}
            initiativeId={initiativeId}
          />
        )),
      ]),
    ],
  };
};
