import { colorPalette, weightPalette } from "gx-npm-common-styles";

const reqsPostPublishInlineAlertStyles = {
  container: {
    alignItems: "center",
    display: "flex",
    fontVariationSettings: weightPalette.bold.variation,
    fontWeight: weightPalette.bold.amount,
    height: "48px",
    justifyContent: "space-between",
    paddingRight: "16px",
    marginBottom: "12px",
    marginTop: "12px",
    width: "100%",
    "&.inline-alert": {
      backgroundColor: colorPalette.status.lightLemon.hex,
      height: "auto",
      marginBottom: "48px",
      padding: "16px 56px 16px 56px",
      "& .warning-message": {
        color: colorPalette.status.darkLemon.hex,
        maxWidth: "600px",
      },
      "& .mini-btn:hover": {
        backgroundColor: colorPalette.interactions.lightBerry.hex,
      },
    },
  },
  containerGCOM4449: {
    alignItems: "center",
    display: "flex",
    fontVariationSettings: weightPalette.bold.variation,
    fontWeight: weightPalette.bold.amount,
    height: "48px",
    justifyContent: "space-between",
    paddingRight: "16px",
    marginBottom: "12px",
    marginTop: "12px",
    width: "100%",
    "&.inline-alert": {
      backgroundColor: colorPalette.status.lightLemon.hex,
      "&.invalid-weight": {
        backgroundColor: colorPalette.status.lightCherry.hex,
        "& .warning-message": {
          color: colorPalette.status.poisonCherry.hex,
        },
        "& .mini-btn:hover, .mini-btn:focus": {
          backgroundColor: colorPalette.status.mediumCherry.hex,
        },
      },
      height: "auto",
      marginBottom: "48px",
      padding: "16px 56px 16px 56px",
      "& .warning-message": {
        color: colorPalette.status.darkLemon.hex,
        marginLeft: "16px",
        maxWidth: "600px",
      },
      "& .mini-btn:hover, .mini-btn:focus": {
        backgroundColor: colorPalette.interactions.lightBerry.hex,
      },
    },
  },
  buttonWrapper: {
    alignItems: "center",
    display: "flex",
    gap: "24px",
  },
  icon: {
    marginTop: "3px",
  },
  flipIcon: {
    marginBottom: "8px",
    transform: "rotate(180deg)",
  },
  message: {
    alignItems: "center",
    display: "flex",
  },
};

export default reqsPostPublishInlineAlertStyles;
