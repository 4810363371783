import { colorPalette } from "gx-npm-common-styles";

const surveyTeamListStyles = {
  noContacts: {
    "&.gx-p": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  recipientList: {
    paddingTop: "40px",
    height: "calc(100vh - 346px)",
    overflowY: "auto",
  },
};

export default surveyTeamListStyles;
