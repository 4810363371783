import React, { useCallback, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import Popper from "@mui/material/Popper";
import classes from "./chart.component.styles.module.scss";
import classNames from "classnames";
import { ChartPopperContent, LegendColors } from "../../survey-table.constants";
import { colorPalette } from "gx-npm-common-styles";
import { TypographyComponent } from "gx-npm-ui";
import { ChartData, ChartProps, HEIGHT, HOVER_PADDING, INNER_RADIUS, OUTER_RADIUS, WIDTH } from "./chart.constants";
import { ChartPopperComponent } from "./chart-popper/chart-popper.component";

const ChartComponent: React.FC<ChartProps> = ({ data, total, category, product }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isChartPopperOpen, setIsChartPopperOpen] = useState(false);
  const [chartPopperContent, setChartPopperContent] = useState<ChartPopperContent | null>(null);
  const chartAnchorRef = useRef<HTMLDivElement | null>(null);
  const arrowRef = useRef<HTMLSpanElement | null>(null);
  const prevDataRef = useRef<ChartData[]>(data);

  const createArcGenerator = () =>
    d3.arc<d3.PieArcDatum<ChartData>>().innerRadius(INNER_RADIUS).outerRadius(OUTER_RADIUS).padAngle(0.06);

  const createHoverBorderArc = () =>
    d3
      .arc<d3.PieArcDatum<ChartData>>()
      .innerRadius(OUTER_RADIUS - 3 + 8)
      .outerRadius(OUTER_RADIUS - 3 + HOVER_PADDING)
      .padAngle(0.06);

  const updatePopperContent = useCallback(
    (d: d3.PieArcDatum<ChartData>) => {
      const initPriorityCount: { [key: number]: number } = { 1: 0, 2: 0, 3: 0 };
      const popperContent: ChartPopperContent = {
        product,
        chartData: data[d.index],
        prioritiesCount: initPriorityCount,
      };

      const { responseOptionId } = data[d.index];

      if (category) {
        popperContent.prioritiesCount = category.requirements.reduce((acc, { priority, id }) => {
          const uploadedRequirement = product.categories[category.id].requirements[id];
          if (
            priority >= 1 &&
            priority <= 3 &&
            uploadedRequirement &&
            uploadedRequirement.responseOptionId === responseOptionId
          ) {
            acc[priority] += 1;
          }

          return acc;
        }, initPriorityCount);
      }

      setChartPopperContent(popperContent);
    },
    [category, data, product]
  );

  useEffect(() => {
    const arcGenerator = createArcGenerator();
    const hoverBorderArc = createHoverBorderArc();
    const pieGenerator = d3
      .pie<ChartData>()
      .value((d) => d.value)
      .sort(null);

    const svg = d3
      .select(svgRef.current)
      .attr("width", WIDTH)
      .attr("height", HEIGHT)
      .selectAll("g")
      .data([null])
      .join("g")
      .attr("transform", `translate(${WIDTH / 2}, ${HEIGHT / 2})`);

    svg.selectAll(".segment-selected").remove();
    svg.selectAll(".white-outline").remove();
    svg.selectAll(".blue-outline").remove();

    const pieData = pieGenerator(data);

    svg
      .selectAll("path.segment-base")
      .data(pieData)
      .join("path")
      .attr("class", "segment-base")
      .attr("fill", (d) => LegendColors[d.index % LegendColors.length])
      .attr("d", arcGenerator)
      .attr("opacity", total === 0 ? 0.1 : 1);

    if (selectedIndex !== -1 && total !== 0) {
      const selectedSegment = pieData[selectedIndex];

      svg
        .append("path")
        .attr("class", "blue-outline")
        .datum(selectedSegment)
        .attr("fill", "none")
        .attr("stroke", colorPalette.interactions.defaultCta.hex)
        .attr("stroke-width", 10)
        .attr("d", arcGenerator);

      svg
        .append("path")
        .attr("class", "white-outline")
        .datum(selectedSegment)
        .attr("fill", "none")
        .attr("stroke", "white")
        .attr("stroke-width", 6)
        .attr("d", arcGenerator);

      svg
        .append("path")
        .attr("class", "segment-selected")
        .datum(selectedSegment)
        .attr("fill", LegendColors[selectedIndex % LegendColors.length])
        .attr("d", arcGenerator);
    }

    svg
      .selectAll("path.segment-interaction")
      .data(pieData)
      .join("path")
      .attr("class", "segment-interaction")
      .attr("fill", "transparent")
      .attr("d", arcGenerator)
      .style("cursor", total === 0 ? "default" : "pointer")
      .on("mouseover", (event, d) => {
        if (total === 0 || d.index === selectedIndex) {
          return;
        }

        svg.selectAll(".hover-border").remove();
        setHoveredIndex(d.index);
        setAnchorEl(event.currentTarget);

        svg
          .append("path")
          .attr("class", "hover-border")
          .attr("d", hoverBorderArc(d))
          .attr("fill", colorPalette.interactions.defaultCta.hex);
      })
      .on("mouseout", () => {
        if (total !== 0 && hoveredIndex !== selectedIndex) {
          setHoveredIndex(-1);
          setAnchorEl(null);
          svg.selectAll(".hover-border").remove();
        }
      })
      .on("click", (event, d) => {
        event.stopPropagation();
        document.body.click(); // close all poppers
        if (total === 0) {
          return;
        }

        chartAnchorRef.current = event.currentTarget;
        updatePopperContent(d);

        if (selectedIndex !== d.index) {
          svg.selectAll(".hover-border").remove();
          setSelectedIndex(d.index);
          setIsChartPopperOpen(true);
          setAnchorEl(null);
        }
      });

    if (!d3.select(svgRef.current).selectAll("path.segment-base").empty() && prevDataRef.current !== data) {
      svg
        .selectAll("path.segment-base")
        .transition()
        .duration(1000)
        .attrTween("d", function (d) {
          const interpolate = d3.interpolate(
            { startAngle: 0, endAngle: 0 } as d3.PieArcDatum<ChartData>,
            d as d3.PieArcDatum<ChartData>
          );

          return function (t: number): string {
            const interpolated = interpolate(t);
            const path = arcGenerator(interpolated as d3.PieArcDatum<ChartData>);
            return path !== null ? path : "";
          };
        });
    }

    prevDataRef.current = data;
    svg.selectAll("text").remove();
  }, [data, total, selectedIndex, hoveredIndex, updatePopperContent]);

  const handleClickAway = () => {
    setSelectedIndex(-1);
    setIsChartPopperOpen(false);
  };

  return (
    <div data-testid={"chart-container"} className={classes.chartContainer}>
      <svg ref={svgRef} />
      {total > 0 && anchorEl && hoveredIndex > -1 && hoveredIndex !== selectedIndex && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          className={classNames(classes.popper)}
          placement="top"
          modifiers={[
            { name: "offset", options: { offset: [0, 12] } },
            { name: "arrow", options: { element: arrowRef.current } },
            { name: "preventOverflow", options: { boundary: "viewport", padding: 8 } },
          ]}
        >
          <div className={classes.popperContent}>
            <TypographyComponent element={"span"} styling={"p4"} boldness={"medium"}>
              {data[hoveredIndex].label}
            </TypographyComponent>
            <TypographyComponent
              rootClassName={classes.responseOptionCount}
              element={"span"}
              styling={"p4"}
              boldness={"regular"}
              color={"iron"}
            >
              ({data[hoveredIndex].value})
            </TypographyComponent>
          </div>
          <span className={classNames(classes.gxPopperArrow, classes.bottom)} ref={arrowRef} />
        </Popper>
      )}
      <ChartPopperComponent
        onClose={handleClickAway}
        anchor={chartAnchorRef.current}
        open={isChartPopperOpen}
        chartPopperContent={chartPopperContent}
      />
    </div>
  );
};

export default ChartComponent;
