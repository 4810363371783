import { UUID } from "gx-npm-lib";
import { TypographyComponent } from "gx-npm-ui";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import { useParams } from "react-router-dom";
import SelectionAnimationJSON from "../../../../assets/lottie/selectionAnimation.json";
import { SelectionContext } from "../../selection.context";
import styles from "./awarded-animation-v2.styles.module.scss";

const AwardedAnimationV2Component = () => {
  const { t } = useTranslation();
  const { initiativeId = "" } = useParams<{ initiativeId: UUID }>();
  const { setShowAnimation } = useContext(SelectionContext);

  const handleComplete = () => {
    localStorage.setItem(`showAnimationOnSelection-${initiativeId}`, "false");
    setShowAnimation(false);
  };

  return (
    <div>
      <TypographyComponent color="black" rootClassName={styles.text}>
        {t("We're gathering your selection summary")}
        {` ...`}
      </TypographyComponent>
      <Lottie
        animationData={SelectionAnimationJSON}
        className={styles.animator}
        loop={false}
        onComplete={handleComplete}
        play={true}
      />
    </div>
  );
};

export default AwardedAnimationV2Component;
