// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y3S9XfPt6DOUTAGnvWVg{margin-top:18px}.Y3S9XfPt6DOUTAGnvWVg .D1iUVrZcSB2_44Qdd24A{background-color:var(--color-interactions-mild-berry) !important}`, "",{"version":3,"sources":["webpack://./src/sections/survey/survey-table/survey-table.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEA,4CACE,gEAAA","sourcesContent":[".root {\n  margin-top: 18px;\n\n  .cellActive {\n    background-color: var(--color-interactions-mild-berry) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Y3S9XfPt6DOUTAGnvWVg`,
	"cellActive": `D1iUVrZcSB2_44Qdd24A`
};
export default ___CSS_LOADER_EXPORT___;
