import React, { ReactNode, KeyboardEvent } from "react";
import classNames from "classnames";
import { Drawer } from "@mui/material";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { colorPalette } from "gx-npm-common-styles";
import { TypographyComponent } from "../typography/typography.component";
import { ButtonLoader } from "../button-loader/button-loader.component";
import { Button } from "../button";
import styles from "./slide-in-overlay-v2.styles.module.scss";

type SlideInOverlayV2Props = {
  children: ReactNode;
  darth?: boolean;
  headerLogo?: ReactNode | string;
  isOpen?: boolean;
  isOverlayNavBar?: boolean;
  onClose?: () => void;
  rootClassName?: string;
  textBackBtn?: ReactNode | string;
  textTitle?: ReactNode | string;
  customHeader?: ReactNode;
  customFooter?: ReactNode;
  customPrimaryButtonFooter?: ReactNode;
  primaryButtonLabel?: string;
  primaryButtonAction?: (...args: never[]) => void;
  isPrimaryButtonDisabled?: boolean;
  isPrimaryButtonLoading?: boolean;
  customSecondaryButtonFooter?: ReactNode;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: (...args: never[]) => void;
  isSecondaryButtonDisabled?: boolean;
};

export const SlideInOverlayV2Component: React.FC<SlideInOverlayV2Props> = ({
  children,
  darth,
  headerLogo,
  isOpen,
  isOverlayNavBar,
  onClose,
  rootClassName,
  textBackBtn,
  textTitle,
  customHeader,
  customFooter,
  customPrimaryButtonFooter,
  primaryButtonLabel,
  primaryButtonAction,
  isPrimaryButtonDisabled,
  isPrimaryButtonLoading,
  customSecondaryButtonFooter,
  secondaryButtonLabel,
  secondaryButtonAction,
  isSecondaryButtonDisabled,
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Enter") {
      handleEvent(onClose);
    }
  };

  return (
    <Drawer
      anchor="right"
      classes={{ root: styles.root, paper: styles.paper }}
      className={classNames(rootClassName)}
      onClose={() => handleEvent(onClose)}
      open={isOpen}
      transitionDuration={1000}
    >
      <section className={classNames("gx-slidein-header", styles.containerHeader, darth && styles.darth)}>
        {customHeader || (
          <div
            className={classNames(styles.headerBtn, darth && styles.darth)}
            onClick={() => handleEvent(onClose)}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            aria-label="close"
          >
            <ArrowBackIcon fillPath={darth ? colorPalette.basic.white.hex : colorPalette.interactions.defaultCta.hex} />
            <TypographyComponent
              element="span"
              boldness="semi"
              rootClassName={classNames(styles.btnText, styles.btnStartText)}
              color="inherit"
              styling="p3"
            >
              {textBackBtn ? "BACK TO" : "BACK"}
            </TypographyComponent>
            <TypographyComponent
              element="span"
              boldness="semi"
              rootClassName={classNames(styles.btnText)}
              color="inherit"
              styling="p3"
            >
              {textBackBtn}
            </TypographyComponent>
          </div>
        )}
        {textTitle && (
          <TypographyComponent
            styling="p1"
            boldness="medium"
            color="iron"
            rootClassName={classNames(styles.headerTitle)}
          >
            {textTitle}
          </TypographyComponent>
        )}
        <div className={styles.headerLogo}>{darth ? headerLogo : <BuySmartLogoOrangeStacked />}</div>
      </section>
      <section
        className={classNames(
          "gx-slidein-body",
          darth && styles.darth,
          styles.containerBody,
          isOverlayNavBar && styles.statusOverlayNav
        )}
      >
        {children}
      </section>
      <div className={classNames("gx-slidein-footer", styles.footer)}>
        {customFooter || (
          <div className={styles.footerWrapper}>
            {customSecondaryButtonFooter || (
              <Button
                disabled={isSecondaryButtonDisabled}
                onClick={secondaryButtonAction}
                rootClassName={classNames(styles.cancelBtn, "btn-tertiary")}
              >
                {secondaryButtonLabel || "CANCEL"}
              </Button>
            )}
            {customPrimaryButtonFooter || (
              <ButtonLoader
                btnClass="btn-primary"
                disabled={isPrimaryButtonDisabled}
                handleButtonClick={primaryButtonAction}
                isLoading={isPrimaryButtonLoading}
                rootClassName="btn-block"
              >
                {primaryButtonLabel || "OK"}
              </ButtonLoader>
            )}
          </div>
        )}
      </div>
    </Drawer>
  );
};
