// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../assets/images/no-vendor-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CXz7hKf5lqalhAyI6gbx{min-height:190px;padding:20px 32px 32px;border-radius:8px;width:100%;background-color:var(--color-white);background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-size:contain;background-repeat:no-repeat;background-position:right}.CXz7hKf5lqalhAyI6gbx .eHKYH_x3FHJr1lzPyHjE{margin-top:20px}.CXz7hKf5lqalhAyI6gbx .iyKaGnjrHj_cyBl0sl19{margin-top:24px}`, "",{"version":3,"sources":["webpack://./src/sections/survey/survey-table/components/no-product-view/no-product-view.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,sBAAA,CACA,iBAAA,CACA,UAAA,CACA,mCAAA,CACA,wDAAA,CACA,uBAAA,CACA,2BAAA,CACA,yBAAA,CAEA,4CACE,eAAA,CAGF,4CACE,eAAA","sourcesContent":[".root {\n  min-height: 190px;\n  padding: 20px 32px 32px;\n  border-radius: 8px;\n  width: 100%;\n  background-color: var(--color-white);\n  background-image: url(\"../../../../../assets/images/no-vendor-background.png\");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: right;\n\n  .noVendorText {\n    margin-top: 20px;\n  }\n\n  .goToBtn {\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CXz7hKf5lqalhAyI6gbx`,
	"noVendorText": `eHKYH_x3FHJr1lzPyHjE`,
	"goToBtn": `iyKaGnjrHj_cyBl0sl19`
};
export default ___CSS_LOADER_EXPORT___;
