import React from "react";

export type Cell = HTMLElement | React.ReactElement | string;

export type RowSet = {
  title: React.ReactElement | string | HTMLElement;
  rows: Cell[][];
};

export const LAST_TABLE_COLUMN_CLASS_NAME = "last-table-column";
export const SCROLLABLE_DIV_CLASS_NAME = "scrollable-div";
