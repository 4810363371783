import { ReactElementLike } from "prop-types";
import { useTranslation } from "react-i18next";
import { SlideInOverlay } from "../../slideInOverlay";
import { TypographyComponent } from "../../typography/typography.component";
import MagicQuadSectionV2Component from "./magic-quad-section-v2/magic-quad-section-v2.component";
import MarketGuideSectionV2Component from "./market-guide-section-v2/market-guide-section-v2.component";
import PeerInsightsSectionV2Component from "./peer-insights-section-v2/peer-insights-section-v2.component";
import SlideInHeaderComponent from "./slide-in-header/slide-in-header.component";
import styles from "./research-slide-in-v2.styles.module.scss";

type ResearchSlideInV2Props = {
  backToText: string;
  isOpen: boolean;
  magicQuadResId?: number;
  marketGuideResId?: number;
  onClose: () => void;
  piMarketSeoName?: string;
  templateName?: string;
};
const ResearchSlideInV2Component = ({
  backToText = "",
  isOpen = false,
  magicQuadResId = -1,
  marketGuideResId = -1,
  onClose = () => {},
  piMarketSeoName = "",
  templateName = "",
}: ResearchSlideInV2Props) => {
  const { t } = useTranslation();
  return (
    <SlideInOverlay
      darth={true}
      headerLogo={(<SlideInHeaderComponent />) as ReactElementLike}
      isOpen={isOpen}
      onClose={onClose}
      textBackBtn={` ${backToText}`}
    >
      <section className={styles.root}>
        <TypographyComponent color="quartz" rootClassName={styles.primaryHeader} styling="h2">
          {templateName}
        </TypographyComponent>
        <div className={styles.divider} />
        <div>
          <div>
            <TypographyComponent color="berry" rootClassName={styles.secondaryHeader} styling="h3">
              {t("Gartner research behind this market")}
            </TypographyComponent>
            <div role="complementary">
              <TypographyComponent color="silver" rootClassName={styles.paragraphOne} styling="p2">
                {t("BuySmart combines the")}
                {` `}
                <TypographyComponent boldness="medium" color="silver" element="span">
                  {t("power of curated Gartner research, peer and expert insights, and intuitive workflow processes")}
                </TypographyComponent>
                {` `}
                {t(
                  "into markets. Each market is based on Gartner research that uses our proprietary methodologies " +
                    "and an independent operating model for objective business and technology insights."
                )}
              </TypographyComponent>
              <TypographyComponent color="silver" rootClassName={styles.paragraphTwo} styling="p2">
                {t(
                  "This market has been created from a variety of published Gartner research documents and data sources."
                )}
              </TypographyComponent>
            </div>
          </div>
          <div className={styles.researchContentV2}>
            {magicQuadResId > 0 && <MagicQuadSectionV2Component resId={magicQuadResId} templateName={templateName} />}
            {marketGuideResId > 0 && (
              <MarketGuideSectionV2Component resId={marketGuideResId} templateName={templateName} />
            )}
            <PeerInsightsSectionV2Component piMarketSeoName={piMarketSeoName} templateName={templateName} />
          </div>
        </div>
      </section>
    </SlideInOverlay>
  );
};

export default ResearchSlideInV2Component;
