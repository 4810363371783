export enum InitStatus {
  PLANNING = "PLANNING",
  EVAL_STARTED = "EVAL_STARTED",
  SELECTED = "SELECTED",
}

export enum InitState {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}

export enum InitProdState {
  AWARDED = "AWARDED",
  IN_EVALUATION = "IN_EVALUATION",
  LISTED = "LISTED",
  SCREENED_OUT = "SCREENED_OUT",
}

export enum InitUserRole {
  CONTRIBUTOR = "CONTRIBUTOR",
  OWNER = "OWNER",
  VIEWER = "VIEWER",
}

export enum InitUserRoleLabel {
  CONTRIBUTOR = "Contributor",
  OWNER = "Owner",
  VIEWER = "Viewer",
}

export enum ChecklistPhaseStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

export enum ReviewType {
  FAVORABLE = "FAVORABLE",
  CRITICAL = "CRITICAL",
}

export enum ScorecardProgress {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

export enum ScoringLevel {
  CATEGORY = "CATEGORY",
  REQUIREMENT = "REQUIREMENT",
}

export enum ScoringMode {
  NONE = "NONE",
  AUTO_ASSIGNMENT = "AUTO_ASSIGNMENT",
  MANUAL_ASSIGNMENT = "MANUAL_ASSIGNMENT",
}

export enum RequirementsState {
  DRAFT = "DRAFT",
  INEDIT = "INEDIT",
  PUBLISHED = "PUBLISHED",
}

export enum PriorityWeightOption {
  NONE = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}

export enum FreeTrialRole {
  FREE_TRIAL_RECIPIENT = "FREE_TRIAL_RECIPIENT",
  FREE_TRIAL_GUEST = "FREE_TRIAL_GUEST",
}

export enum GartnerQuadrant {
  TOP_LEFT = "Challenger",
  TOP_RIGHT = "Leader",
  BOTTOM_RIGHT = "Visionary",
  BOTTOM_LEFT = "Niche Player",
  NONE = "NONE",
}

export enum GcomDocType {
  MARKET_GUIDE = "MARKET_GUIDE",
  EMERGING_MARKET_QUAD = "EMERGING_MARKET_QUAD",
  MAGIC_QUAD = "MAGIC_QUAD",
  TOOL = "TOOL",
}
