import classNames from "classnames";
import { deleteAsyncRequest, InitProdState, postAsyncRequest, putAsyncRequest, UUID } from "gx-npm-lib";
import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ListItemsContainer } from "../../../../ui/dragAndDropList/body";
import { DragAndDropList } from "../../../../ui/dragAndDropList";
import { ProductsContext } from "../../products.context";
import { InitiativeProduct } from "../../products.types";
import styles from "./products-list-v2.styles.module.scss";
import ProductsAddV2Component from "../products-add-v2/products-add-v2.component";
import ProductListHeaderV3Component from "./product-list-header-v3/product-list-header-v3.component";
import ProductListRowV3Component from "./product-list-row-v3/product-list-row-v3.component";
import { ProductListRowButtonAction } from "./product-list-row-v3/product-list-row-v3.constants";

type DragItemFinishResults = { destination: { index: number }; source: { index: number } };
const ProductsListV2Component = () => {
  const { t } = useTranslation();
  const { initId, products, setProducts, setProductsErrorMessage, setProductsSuccessMessage } =
    useContext(ProductsContext);
  const [initProdIdAddedToEval, setInitProdIdAddedToEval] = useState<UUID>("");
  const [initProdIdRemovedFromEvaldToEval, setInitProdIdRemovedFromEvaldToEval] = useState<UUID>("");
  const handleAction = async (action: ProductListRowButtonAction, initProdId: UUID, prodName: string) => {
    if (action === ProductListRowButtonAction.ADD) {
      await handleActionAddToEval(initProdId, prodName);
    } else if (action === ProductListRowButtonAction.REMOVE) {
      await handleActionRemoveFromEval(initProdId, prodName);
    } else if (action === ProductListRowButtonAction.DELETE) {
      await handleActionDeleteFromEval(initProdId, prodName);
    }
  };

  const handleDragFinish = async (_items: Array<InitiativeProduct>, results: DragItemFinishResults) => {
    const destIndex = results.destination.index;
    const sourceIndex = results.source.index;
    if (destIndex === sourceIndex || destIndex > products.length - 1 || sourceIndex > products.length - 1) {
      return;
    }
    setProducts((prev) => {
      const newProducts = [...prev];
      const [moved] = newProducts.splice(sourceIndex, 1);
      newProducts.splice(destIndex, 0, moved);
      return newProducts;
    });
    const initProdId = products[sourceIndex].id;
    const url = `api/v3/initiatives/${initId}/products/${initProdId}/action/reorder`;
    const response = await putAsyncRequest(url, { index: destIndex });
    if (response?.status !== 200 || typeof response.data !== "object") {
      setProductsErrorMessage(`${t("Oops... something went wrong. Please try again.")}`);
    }
  };

  const handleActionAddToEval = async (initProdId: UUID, prodName: string) => {
    setInitProdIdAddedToEval(initProdId);
    const url = `api/v4/initiatives/${initId}/products/${initProdId}/action/add-to-eval`;
    const response = await putAsyncRequest(url);
    if (response?.status === 200 && typeof response.data === "object") {
      setProducts((prev) => {
        return prev.map((product) => {
          return product.id === initProdId ? { ...product, state: InitProdState.IN_EVALUATION } : product;
        });
      });
      setProductsSuccessMessage(`${prodName} ${t("has been added to your evaluation.")}`);
    } else {
      setProductsErrorMessage(`${t("There was a issue while adding")} ${prodName} ${t("to evaluation")}`);
    }
    setInitProdIdAddedToEval("");
  };

  const handleActionDeleteFromEval = async (initProdId: UUID, prodName: string) => {
    const url = `api/v3/initiatives/${initId}/products/${initProdId}`;
    const response = await deleteAsyncRequest(url);
    if (response?.status === 200 && typeof response.data === "object") {
      setProducts((prev) => {
        return prev.filter((product) => product.id !== initProdId);
      });
      setProductsSuccessMessage(`${prodName} ${t("has been deleted from your vendor list.")}`);
    } else {
      setProductsErrorMessage(`${t("There was an issue while deleting")} ${prodName} ${t("from your vendor list")}`);
    }
  };

  const handleActionRemoveFromEval = async (initProdId: UUID, prodName: string) => {
    setInitProdIdRemovedFromEvaldToEval(initProdId);
    const url = `api/v3/initiatives/${initId}/products/${initProdId}/action/remove-from-eval`;
    const response = await postAsyncRequest(url);
    if (response?.status === 201 && typeof response.data === "object") {
      setProducts((prev) => {
        return prev.map((product) => {
          return product.id === initProdId ? { ...product, state: InitProdState.LISTED } : product;
        });
      });
      setProductsSuccessMessage(`${prodName} ${t("has been removed from your evaluation.")}`);
    } else {
      setProductsErrorMessage(`${t("There was an issue while removing")} ${prodName} ${t("from your evaluation.")}`);
    }
    setInitProdIdRemovedFromEvaldToEval("");
  };

  return (
    <div className={classNames(styles.productWrapper)}>
      <div className={styles.content}>
        <DragAndDropList rootClassName={styles.dndRoot}>
          <ProductListHeaderV3Component />
          <ListItemsContainer
            draggable={true}
            itemList={products}
            onDragFinish={(items, pos) => handleDragFinish(items, pos)}
          >
            <Fragment>
              {products.map((product, idx) => {
                return (
                  <ProductListRowV3Component
                    key={product.id}
                    addedByUser={product.addedByUser}
                    id={product.id}
                    imageLoc={product.imageLoc}
                    index={idx}
                    isAdding={initProdIdAddedToEval === product.id}
                    isRemoving={initProdIdRemovedFromEvaldToEval === product.id}
                    priorityScore={product.priorityScore}
                    mqPosition={product.mqPosition}
                    name={product.name}
                    onAction={(action: ProductListRowButtonAction) => handleAction(action, product.id, product.name)}
                    peerRating={product.peerRating}
                    peerReviewCount={product.peerReviewCount}
                    state={product.state}
                    isEligiblePR={product.isEligiblePR}
                  />
                );
              })}
            </Fragment>
          </ListItemsContainer>
        </DragAndDropList>
        <ProductsAddV2Component />
      </div>
    </div>
  );
};

export default ProductsListV2Component;
