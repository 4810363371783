import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClickAwayListener, Popper } from "@mui/material";
import classNames from "classnames";
import styles from "./priority-filter.styles.module.scss";
import { Checkbox, MiniButton, TypographyComponent } from "gx-npm-ui";
import { CheckmarkIconV2, ChevronDownIcon, CloseIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { SurveyTableContext } from "../../context/survey-table/survey-table.context";
import { FilterContext } from "../../context/filter/filter.context";

export const PriorityFilterComponent = () => {
  const { t } = useTranslation();
  const popperAnchor = useRef<HTMLDivElement | null>(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [priorityFilterOptions, setPriorityFilterOptions] = useState<string[]>([]);
  const [isClickedAway, setIsClickedAway] = useState(false);
  const { surveyCategories } = useContext(SurveyTableContext);
  const { priorityId, setPriorityId, setPriorityFilteredCategories } = useContext(FilterContext);

  const PRIORITIES: Record<
    number,
    {
      id: string;
      name: string;
    }
  > = Object.freeze({
    1: { id: "3", name: t("High") },
    2: { id: "2", name: t("Medium") },
    3: { id: "1", name: t("Low") },
  });

  useEffect(() => {
    setPriorityFilterOptions([...priorityId]);
  }, [priorityId, isPopperOpen]);

  const togglePopper = () => {
    if (!isClickedAway) {
      setIsPopperOpen((prev) => !prev);
    }
  };

  const togglePopperKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      setIsPopperOpen((prev) => !prev);
      e.stopPropagation();
    }
  }, []);

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    const updatedOption = e.target.name;
    setPriorityFilterOptions((prev) =>
      isChecked ? [...prev, updatedOption] : prev.filter((option) => option !== updatedOption)
    );
  };

  const handleClickAway = () => {
    setIsPopperOpen(false);
    setIsClickedAway(true);
    setTimeout(() => {
      setIsClickedAway(false);
    }, 0);
  };

  const clearAllPriorityFilter = () => {
    setPriorityId([]);
    setIsPopperOpen(false);
    setPriorityFilteredCategories([]);
  };

  const handleApply = () => {
    if (priorityFilterOptions.length === 0) {
      clearAllPriorityFilter();
      return;
    }
    setPriorityId(priorityFilterOptions);
    setIsPopperOpen(false);
    const priorityFilteredCategories = surveyCategories.map((category) => {
      const filteredRequirements = category.requirements.filter((requirement) =>
        priorityFilterOptions.includes(requirement.priority.toString())
      );
      return { ...category, requirements: filteredRequirements };
    });
    setPriorityFilteredCategories(priorityFilteredCategories);
  };

  return (
    <span className={styles.root}>
      <div
        ref={popperAnchor}
        onClick={togglePopper}
        onKeyDown={togglePopperKeyDown}
        className={classNames(
          styles.pill,
          isPopperOpen && styles.active,
          priorityId.length > 0 && styles.appliedFilterState
        )}
        role="button"
        tabIndex={0}
      >
        <TypographyComponent styling="p3">{t("Priority")}</TypographyComponent>
        {priorityId.length > 0 && (
          <TypographyComponent styling="p3" boldness="medium">
            {`: `}
            {priorityId.map((id) => Object.values(PRIORITIES).find((option) => option.id === id)?.name).join(", ")}
          </TypographyComponent>
        )}
        <div className={classNames(styles.iconWrapper, isPopperOpen && styles.rotateChevron)}>
          <ChevronDownIcon />
        </div>
      </div>
      <ClickAwayListener onClickAway={handleClickAway} mouseEvent={"onMouseUp"}>
        <Popper
          open={isPopperOpen}
          anchorEl={popperAnchor.current}
          placement="bottom"
          className={styles.popper}
          modifiers={[
            { name: "offset", options: { offset: [130, 6] } },
            { name: "eventListeners", enabled: true, options: { scroll: true, resize: true } },
            { name: "flip", options: { fallbackPlacements: ["top", "bottom", "left", "right"] } },
          ]}
        >
          <TypographyComponent styling="p5" color="iron">
            {t("Requirement priority")}
          </TypographyComponent>
          {Object.values(PRIORITIES).map((option) => (
            <div key={option.id} className={styles.wrapper}>
              <Checkbox
                label={option.name}
                onChange={handleCheckboxChange}
                name={option.id}
                checked={priorityFilterOptions.includes(option.id)}
                rootClassName={styles.checkboxContainer}
              />
            </div>
          ))}
          <div className={styles.footerContainer}>
            <MiniButton onClick={clearAllPriorityFilter} rootClassName={classNames(styles.clearAllBtn, styles.btn)}>
              <CloseIcon fillPath={colorPalette.neutrals.iron.hex} />
              <TypographyComponent styling="p3" boldness="medium" color="defaultCta">
                {t("Clear all")}
              </TypographyComponent>
            </MiniButton>
            <MiniButton onClick={handleApply} rootClassName={classNames(styles.applyBtn, styles.btn)}>
              <CheckmarkIconV2 />
              <TypographyComponent styling="p3" boldness="medium" color={"defaultCta"}>
                {t("Apply")}
              </TypographyComponent>
            </MiniButton>
          </div>
        </Popper>
      </ClickAwayListener>
    </span>
  );
};
