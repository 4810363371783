import { useMediaQuery } from "@mui/material";

const useResponsiveColumns = (): number => {
  const isSm = useMediaQuery("(max-width: 1024px)");
  const isMd = useMediaQuery("(min-width: 1025px) and (max-width: 1240px)");
  const isLg = useMediaQuery("(min-width: 1241px) and (max-width: 1440px)");

  return isSm ? 1 : isMd ? 2 : isLg ? 3 : 4;
};

export default useResponsiveColumns;
