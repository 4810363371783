import React, { FC } from "react";
import { LAST_TABLE_COLUMN_CLASS_NAME, RowSet, SCROLLABLE_DIV_CLASS_NAME } from "../table.constants";
import classNames from "classnames";
import styles from "./table-footer.styles.module.scss";

type TableFooterProps = {
  rowSet: RowSet;
  isOverflowing: boolean;
};
export const TableFooterComponent: FC<TableFooterProps> = ({ rowSet, isOverflowing }) => {
  return (
    <div className={styles.root}>
      <div className={styles.footerSummary}>{rowSet.title}</div>
      <div className={styles.rowContainer}>
        {rowSet.rows.map((row, rowIndex) => (
          <div
            className={classNames(styles.row, rowIndex === rowSet.rows.length - 1 && styles.noBorder)}
            key={`row-${rowIndex}`}
          >
            <div
              className={classNames({
                [styles.firstColumn]: true,
              })}
            >
              <div>{row[0]}</div>
            </div>
            <div
              className={classNames({
                [styles.columnContainer]: true,
                [SCROLLABLE_DIV_CLASS_NAME]: true,
                [styles.roundedCorner]: !isOverflowing && rowIndex === rowSet.rows.length - 1,
              })}
            >
              {row.slice(1).map((column, columnIndex) => (
                <div
                  className={classNames(styles.column, {
                    [styles.darkColumn]: columnIndex % 2 === 0,
                    [styles.responsiveColumns]: row.length >= 4,
                    [styles.moreThanFourColumns]: row.length >= 5,
                  })}
                  key={`column-${columnIndex + 1}`}
                >
                  {column}
                </div>
              ))}
            </div>
            {isOverflowing && (
              <div
                className={classNames({
                  [styles.lastColumn]: true,
                  [styles.roundedCorner]: rowIndex === rowSet.rows.length - 1,
                  [LAST_TABLE_COLUMN_CLASS_NAME]: true,
                })}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
