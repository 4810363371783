import React, { FC, useContext } from "react";
import * as singleSpa from "single-spa";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ProductLogo, TextLink, TooltipV2, TypographyComponent } from "gx-npm-ui";
import { UUID, InitProdState } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";

import styles from "./scorecard-product-head.styles.module.scss";
import { Product } from "../../../../../../../app.types";
import { CarouselContext } from "../../../carousel.context";

type ScorecardProductHeadProps = {
  initiativeId: UUID;
  isHeaderDocked: boolean;
};

const ScorecardProductHead: FC<ScorecardProductHeadProps> = ({ initiativeId, isHeaderDocked = false }) => {
  const { t } = useTranslation();

  const { visibleProducts } = useContext(CarouselContext);

  const handleNavigation = (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent, url: string) => {
    event.preventDefault();
    updateStateSubject("WORKSPACE_HEADER_SOURCE_PAGE", {
      sourcePage: "scorecard",
    });
    singleSpa.navigateToUrl(url);
  };

  const handleKeyDownNavigation = (event: React.KeyboardEvent<HTMLDivElement>, url: string) => {
    if (event.key === "Enter") {
      handleNavigation(event, url);
    }
  };

  const getUrl = (initProdId: string): string => {
    return `/s/evaluation/${initiativeId}/product/${initProdId}/scoresheet`;
  };

  const getVendorProfileUrl = (initProdId: string): string => {
    return `/s/evaluation/${initiativeId}/product/${initProdId}/profile`;
  };

  const showScoreLink = !isHeaderDocked;

  return (
    <>
      {visibleProducts.map((product: Product) => (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <td
          key={`vendor-info-${product.id}`}
          className={classNames(
            product.state === InitProdState.SCREENED_OUT && "gx-screened-out-bk-gnd",
            product.state === InitProdState.AWARDED ? "gx-awarded" : "gx-not-awarded",
            isHeaderDocked && styles.headerIsDocked
          )}
        >
          <div className={styles.headerWrapper}>
            <div className="gx-evaluations-scorecard-table-vendor">
              <TooltipV2
                placement="top-start"
                PopperProps={{ modifiers: { offset: { offset: "-50, 8" } } }}
                rootClassName={styles.toolTip}
                title={t("Click to view") + " " + product.name + " " + t("profile")}
              >
                <div
                  aria-label={t("View vendor profile for ") + product.name}
                  className={styles.vendorNameLogo}
                  onClick={(e) => handleNavigation(e, getVendorProfileUrl(product.id))}
                  onKeyDown={(e) => handleKeyDownNavigation(e, getVendorProfileUrl(product.id))}
                  role="button"
                  tabIndex={0}
                >
                  <ProductLogo logo={product.imageLoc} name={product.name} imageWidth="36px" imageHeight="36px" />
                  <TypographyComponent boldness={"medium"} rootClassName={styles.paraVendorName} styling={"p3"}>
                    {product.name}
                  </TypographyComponent>
                </div>
              </TooltipV2>
            </div>
            {showScoreLink && (
              <div className={styles.headerButtons}>
                <TextLink
                  ariaLabel={`${t("Evaluation button")} ${product.name}`}
                  href={getUrl(product.id)}
                  onClick={(e) => handleNavigation(e, getUrl(product.id))}
                  rootClassName={classNames("gx-evaluation-view-vendor", styles.viewVendor)}
                  text={t("Score vendor")}
                />
              </div>
            )}
          </div>
        </td>
      ))}
    </>
  );
};

export default ScorecardProductHead;
