import React, { useEffect, useLayoutEffect, useRef, useState, useContext, useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useFeatureFlag } from "gx-npm-ui";
import { makeStyles } from "@material-ui/core/styles";
import { TableCarouselScrollLeft, TableCarouselScrollRight } from "./scroll";
import { TableCarouselFooter } from "./footer";
import { evaluationClasses } from "./styles";
import { handleScroll, getMaxCols } from "./lib";

import { GCOM_493_GCOM_3780__scorecardScoreRowSticky } from "../../lib/feature-flags";
import { CarouselContext } from "../../sections/scorecard-tab/components/scorecard/carousel.context";
import useResponsiveColumns from "./use-responsive-columns";

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  displayAllColumns: PropTypes.bool,
  fixedRow: PropTypes.number,
  footerRowMapper: PropTypes.array,
  rootClassName: PropTypes.string,
  rowSelectorClass: PropTypes.string,
  shadowOffset: PropTypes.number,
  showAllRecords: PropTypes.bool,
  showNextColumnLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  showScreenedOut: PropTypes.bool,
  triggerRefreshColumnsCount: PropTypes.bool,
};
const useStyles = makeStyles(() => evaluationClasses);
const TableCarousel = ({
  children,
  displayAllColumns = false,
  fixedRow = 0,
  footerRowMapper = [],
  rootClassName = "",
  rowSelectorClass = "", //used to query tr with a given class and set the row size
  shadowOffset = 48,
  showAllRecords = false,
  showNextColumnLabel = "",
  showScreenedOut = false,
  triggerRefreshColumnsCount = false,
}) => {
  const classes = useStyles();
  const [currentPosition, setCurrentPosition] = useState(0);
  const [size, setSize] = useState([0, 0]);
  const [rows, setRows] = useState(0);
  const [cols, setCols] = useState(0);
  const [shadow, setShadow] = useState(false);
  const [showScreenedOutVendors, setShowScreenedOutVendors] = useState(false);
  const [hasScrolledToEnd, setHasScrolledToEnd] = useState(false);

  const tableRef = useRef();
  const maxProdCols = useResponsiveColumns();
  const isGCOM3780FFOn = useFeatureFlag(GCOM_493_GCOM_3780__scorecardScoreRowSticky);

  const maxCols = isGCOM3780FFOn ? maxProdCols : getMaxCols();

  const showTableFooter = cols > maxCols ? true : false;

  const { setCurrentPos } = useContext(CarouselContext);

  const scrollTable = useCallback(
    (direction, position) => {
      if (isGCOM3780FFOn) {
        const isAtBeginning = position === 0 && direction === "left";
        const hasReachedEnd = cols - maxProdCols === position + 1;

        if (direction !== "reset") {
          setHasScrolledToEnd(hasReachedEnd);
        }

        if ((!isAtBeginning && !hasScrolledToEnd) || (hasScrolledToEnd && direction === "left")) {
          handleScroll({
            tableRef,
            direction: direction,
            currentPosition: position,
            setCurrentPosition,
          });
        }
      } else {
        handleScroll({
          tableRef,
          direction: direction,
          currentPosition: position,
          setCurrentPosition,
        });
      }
    },
    [isGCOM3780FFOn, cols, maxProdCols, hasScrolledToEnd]
  );

  useEffect(() => {
    if (isGCOM3780FFOn) {
      setCurrentPos(currentPosition);
    }
  }, [currentPosition, setCurrentPos, isGCOM3780FFOn]);

  useEffect(() => {
    autoSizeTableBody();
  }, [cols]);

  useEffect(() => {
    setShowScreenedOutVendors(showScreenedOut);
  }, [showScreenedOut]);

  useEffect(() => {
    const thisTable = tableRef?.current;
    const numberRows = rowSelectorClass
      ? thisTable?.querySelectorAll(`thead tr.${rowSelectorClass}`)?.length + fixedRow
      : thisTable?.querySelectorAll("thead tr")?.length;
    if (numberRows !== rows) {
      setRows(numberRows);
    }

    const firstTableBodyRow = thisTable?.querySelectorAll("tbody tr");
    if (firstTableBodyRow) {
      const numberCols = firstTableBodyRow.length <= 1 ? 0 : firstTableBodyRow[0]?.querySelectorAll("td")?.length;

      if (numberCols !== cols) {
        setCols(numberCols);
        scrollTable("reset", 0);
      }
    }
  }, [cols, fixedRow, rows, rowSelectorClass, showScreenedOutVendors, triggerRefreshColumnsCount, scrollTable]);

  useEffect(() => {
    let observerRefValue = null;

    const tableRO = new ResizeObserver(() => {
      autoSizeTableBody();
    });
    tableRO.observe(tableRef?.current);
    observerRefValue = tableRef?.current;
    return () => {
      if (observerRefValue) {
        tableRO.unobserve(observerRefValue);
      }
    };
  }, []);

  useLayoutEffect(() => {
    const updateSize = () => {
      scrollTable("reset", 0);
      setSize([window?.innerWidth, window?.innerHeight]);
    };
    window?.addEventListener("resize", updateSize);
    return () => window?.removeEventListener("resize", updateSize);
  }, [scrollTable]);

  //set height of body tr
  useLayoutEffect(() => {
    const thisTable = tableRef?.current;
    const trHeadList = thisTable?.querySelectorAll("thead tr.gx-req-root-head-row");
    const trBodyList = thisTable?.querySelectorAll("tbody tr.gx-req-root-body-row");

    if (trHeadList.length > 0 && trBodyList.length > 0) {
      for (let i = 0; i < trHeadList.length; i++) {
        //for each head row
        const elemList = trHeadList[i]?.querySelectorAll(
          //get all elements whose height is used as reference
          "th .gx-get-element-height"
        );

        for (let j = 0; j < elemList.length; j++) {
          //then for each of those element
          const setTdList = trBodyList[i].querySelectorAll("td"); //pick the first body row matching the head row index and get all TDs. #TD===#vendors in each body row
          for (let k = 0; k < setTdList.length; k++) {
            //for each vendor TD
            const setElementList = setTdList[k].querySelectorAll(
              //get all element whose height need to be set
              ".gx-set-element-height"
            );
            setElementList[j].style.height = `${elemList[j].offsetHeight}px`; //set the height.
          }
        }
      }
    }
  }, [showAllRecords, cols, tableRef?.current?.offsetHeight]);

  //set height of footer
  useLayoutEffect(() => {
    const thisTable = tableRef?.current;
    if (showAllRecords) {
      const trFootList = thisTable?.querySelectorAll("tfoot tr .gx-set-element-height-footer");
      const trHeadList = thisTable?.querySelectorAll("thead tr .gx-get-element-height");

      if (trFootList.length > 0) {
        for (let i = 0; i < trFootList.length; i++) {
          trFootList[i].style.height = `${trHeadList[i].offsetHeight}px`;
        }
      }
    } else {
      const trFootListLi = thisTable?.querySelectorAll("tfoot tr li.gx-req-item-footer");
      trFootListLi.forEach((listItem) => (listItem.style.height = "0px"));

      const trFootListRoot = thisTable?.querySelectorAll("tfoot tr div.gx-req-root-footer");
      const trHeadListRoot = thisTable?.querySelectorAll("thead tr div.gx-req-root-category");
      if (trFootListRoot.length > 0) {
        for (let i = 0; i < trFootListRoot.length; i++) {
          trFootListRoot[i].style.height = `${trHeadListRoot[i].offsetHeight}px`;
        }
      }
    }
  }, [cols, size, showAllRecords]);

  useEffect(() => {
    const addShadow = () => {
      const topPos = tableRef?.current?.getBoundingClientRect()?.top;
      if (topPos <= shadowOffset) {
        setShadow(true);
      } else if (topPos > shadowOffset) {
        setShadow(false);
      }
    };
    document?.addEventListener("scroll", addShadow);
    return () => document?.removeEventListener("scroll", addShadow);
  }, [shadowOffset]);

  const autoSizeTableBody = () => {
    const tableWidth = tableRef?.current?.offsetWidth;
    const tableHeadWidth = tableRef?.current?.querySelector("thead")?.offsetWidth || 0;
    const tableFootWidth = tableRef?.current?.querySelector("tfoot")?.offsetWidth || 0;
    const tableBody = tableRef?.current?.querySelector("tbody");

    if (tableBody) {
      tableBody.style.width = `${tableWidth - tableHeadWidth - tableFootWidth}px`;
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    let tableCarouselScrollLeft = {};

    if (cols > maxCols) {
      tableCarouselScrollLeft = {
        tableCarouselScrollLeft: (
          <div className={classes.scrollLeftContainer}>
            <TableCarouselScrollLeft
              showNextColumnLabel={showNextColumnLabel}
              disabled={currentPosition === 0 ? true : false}
              onClick={() => scrollTable("left", currentPosition)}
            />
          </div>
        ),
      };
    }
    return React.isValidElement(child) ? React.cloneElement(child, tableCarouselScrollLeft) : child;
  });

  return (
    <table
      className={classNames(
        classes.tableCarousel,
        rootClassName,
        cols === 0 ? "empty" : "not-empty",
        `num-cols-${cols}`,
        displayAllColumns ? "gx-display-all-columns" : "",
        `nav-next-color-${(currentPosition + maxCols) % 2 === 0 ? "gray" : "white"}`,
        shadow ? "header-shadow" : "",
        !showTableFooter ? "no-footer" : ""
      )}
      ref={tableRef}
    >
      {childrenWithProps}
      {showTableFooter && (
        <TableCarouselFooter
          rows={rows}
          tableCarouselScrollRight={
            <TableCarouselScrollRight
              showNextColumnLabel={showNextColumnLabel}
              disabled={isGCOM3780FFOn ? hasScrolledToEnd : currentPosition < cols - maxCols ? false : true}
              onClick={() => scrollTable("right", currentPosition)}
            />
          }
          footerRowMapper={footerRowMapper}
        />
      )}
    </table>
  );
};

TableCarousel.propTypes = propTypes;
export { TableCarousel };
