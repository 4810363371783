export enum CustomAppEvent {
  FATAL_ERROR = "FATAL_ERROR",
  INITIATIVE_STATUS_UPDATE = "INITIATIVE_STATUS_UPDATE",
  OVERVIEW_EDIT_COUNT = "OVERVIEW_EDIT_COUNT",
  SELECTION_EDIT_COUNT = "SELECTION_EDIT_COUNT",
}

export enum WebSocketMessageAction {
  MESSAGE = "message",
}

export enum WebSocketMessageEvent {
  CONNECT = "CONNECT",
  INITIATIVE_ACCESS_REVOKED = "INITIATIVE_ACCESS_REVOKED",
  INITIATIVE_DELETED = "INITIATIVE_DELETED",
  RELOAD_HEADER_DATA = "RELOAD_HEADER_DATA",
  UPDATE_INITIATIVE_STATE = "UPDATE_INITIATIVE_STATE",
  VIEWING_INITIATIVE = "VIEWING_INITIATIVE",
}

export enum ClientEvent {
  INITIATIVE_OVERVIEW_PAGE_VIEWED = "INITIATIVE_OVERVIEW_PAGE_VIEWED",
  INITIATIVE_CHECKLIST_PAGE_VIEWED = "INITIATIVE_CHECKLIST_PAGE_VIEWED",
  INITIATIVE_CHECKLIST_MY_ASSIGNED_TASKS_TOGGLED = "INITIATIVE_CHECKLIST_MY_ASSIGNED_TASKS_TOGGLED",
  INITIATIVE_PRODUCT_PAGE_VIEWED = "INITIATIVE_PRODUCT_PAGE_VIEWED",
  INITIATIVE_REQUIREMENTS_ADDITIONAL_LIBRARY_VIEWED = "INITIATIVE_REQUIREMENTS_ADDITIONAL_LIBRARY_VIEWED",
  INITIATIVE_REQUIREMENTS_DEFAULT_LIBRARY_VIEWED = "INITIATIVE_REQUIREMENTS_DEFAULT_LIBRARY_VIEWED",
  INITIATIVE_REQUIREMENTS_LIBRARY_ITEM_ADDED = "INITIATIVE_REQUIREMENTS_LIBRARY_ITEM_ADDED",
  INITIATIVE_REQUIREMENTS_LIBRARY_VIEWED = "INITIATIVE_REQUIREMENTS_LIBRARY_VIEWED",
  INITIATIVE_REQUIREMENTS_PAGE_VIEWED = "INITIATIVE_REQUIREMENTS_PAGE_VIEWED",
  INITIATIVE_REQUIREMENTS_QUICKSTART_MODAL_OPENED = "INITIATIVE_REQUIREMENTS_QUICKSTART_MODAL_OPENED",
  INITIATIVE_REQUIREMENTS_QUICKSTART_OVERVIEW_CLICKED = "INITIATIVE_REQUIREMENTS_QUICKSTART_OVERVIEW_CLICKED",
  INITIATIVE_REQUIREMENTS_QUICKSTART_SCORING_WEIGHTS_CLICKED = "INITIATIVE_REQUIREMENTS_QUICKSTART_SCORING_WEIGHTS_CLICKED",
  INITIATIVE_REQUIREMENTS_QUICKSTART_IMPORTING_EXPORTING_CLICKED = "INITIATIVE_REQUIREMENTS_QUICKSTART_IMPORTING_EXPORTING_CLICKED",
  INITIATIVE_REQUIREMENTS_QUICKSTART_MODAL_CLOSED = "INITIATIVE_REQUIREMENTS_QUICKSTART_MODAL_CLOSED",
  INITIATIVE_SCORECARD_PAGE_VIEWED = "INITIATIVE_SCORECARD_PAGE_VIEWED",
  INITIATIVE_SCORECARD_PRODUCT_CATEGORY_PROGRESS_POPPER_VIEWED = "INITIATIVE_SCORECARD_PRODUCT_CATEGORY_PROGRESS_POPPER_VIEWED",
  INITIATIVE_SCORECARD_PRODUCT_CATEGORY_SCORE_POPPER_VIEWED = "INITIATIVE_SCORECARD_PRODUCT_CATEGORY_SCORE_POPPER_VIEWED",
  INITIATIVE_SCORECARD_PRODUCT_ITEM_SCORE_POPPER_VIEWED = "INITIATIVE_SCORECARD_PRODUCT_ITEM_SCORE_POPPER_VIEWED",
  INITIATIVE_SCORECARD_QUICKSTART_MODAL_OPENED = "INITIATIVE_SCORECARD_QUICKSTART_MODAL_OPENED",
  INITIATIVE_SCORECARD_QUICKSTART_OVERVIEW_CLICKED = "INITIATIVE_SCORECARD_QUICKSTART_OVERVIEW_CLICKED",
  INITIATIVE_SCORECARD_QUICKSTART_ASSIGNING_SCORERS_CLICKED = "INITIATIVE_SCORECARD_QUICKSTART_ASSIGNING_SCORERS_CLICKED",
  INITIATIVE_SCORECARD_QUICKSTART_ADDING_SCORES_CLICKED = "INITIATIVE_SCORECARD_QUICKSTART_ADDING_SCORES_CLICKED",
  INITIATIVE_SCORECARD_QUICKSTART_CALCULATING_SCORES_CLICKED = "INITIATIVE_SCORECARD_QUICKSTART_CALCULATING_SCORES_CLICKED",
  INITIATIVE_SCORECARD_QUICKSTART_MODAL_CLOSED = "INITIATIVE_SCORECARD_QUICKSTART_MODAL_CLOSED",
  INITIATIVE_SCORECARD_SELECT_VENDOR_CLICKED = "INITIATIVE_SCORECARD_SELECT_VENDOR_CLICKED",
  INITIATIVE_SCORECARD_REMOVE_VENDOR_CLICKED = "INITIATIVE_SCORECARD_REMOVE_VENDOR_CLICKED",
  INITIATIVE_SCORECARD_SCREENOUT_VENDOR_CLICKED = "INITIATIVE_SCORECARD_SCREENOUT_VENDOR_CLICKED",
  INITIATIVE_SELECTION_EXPORT_SAMPLE_SUMMARY_PDF_CLICKED = "INITIATIVE_SELECTION_EXPORT_SAMPLE_SUMMARY_PDF_CLICKED",
  INITIATIVE_SELECTION_PAGE_VIEWED = "INITIATIVE_SELECTION_PAGE_VIEWED",
  INITIATIVE_SELECTION_REPORT_DOWNLOADED = "INITIATIVE_SELECTION_REPORT_DOWNLOADED",
  INITIATIVE_SELECTION_SELECT_VENDOR_CLICKED = "INITIATIVE_SELECTION_SELECT_VENDOR_CLICKED",
  INITIATIVE_SURVEY_PAGE_VIEWED = "INITIATIVE_SURVEY_PAGE_VIEWED",
  INITIATIVE_SURVEY_SENT = "INITIATIVE_SURVEY_SENT",
  INITIATIVE_SURVEY_QUICKSTART_MODAL_OPENED = "INITIATIVE_SURVEY_QUICKSTART_MODAL_OPENED",
  INITIATIVE_SURVEY_QUICKSTART_OVERVIEW_CLICKED = "INITIATIVE_SURVEY_QUICKSTART_OVERVIEW_CLICKED",
  INITIATIVE_SURVEY_QUICKSTART_CREATING_QUESTIONNAIRE_CLICKED = "INITIATIVE_SURVEY_QUICKSTART_CREATING_QUESTIONNAIRE_CLICKED",
  INITIATIVE_SURVEY_QUICKSTART_SENDING_QUESTIONNAIRE_CLICKED = "INITIATIVE_SURVEY_QUICKSTART_SENDING_QUESTIONNAIRE_CLICKED",
  INITIATIVE_SURVEY_QUICKSTART_VIEWING_RESPONSE_CLICKED = "INITIATIVE_SURVEY_QUICKSTART_VIEWING_RESPONSE_CLICKED",
  INITIATIVE_SURVEY_QUICKSTART_MODAL_CLOSED = "INITIATIVE_SURVEY_QUICKSTART_MODAL_CLOSED",
  SURVEY_EXPORTED = "SURVEY_EXPORTED",
  SURVEY_PREVIEW_CLICKED = "SURVEY_PREVIEW_CLICKED",
  SURVEY_RESPONSES_EXPORTED = "SURVEY_RESPONSES_EXPORTED",
}

export enum TabIndex {
  OVERVIEW = 0,
  CHECKLIST = 1,
  REQUIREMENTS = 2,
  PRODUCTS = 3,
  SURVEY = 4,
  SCORECARD = 5,
  SELECTION = 6,
}

export enum TabName {
  OVERVIEW = "overview",
  CHECKLIST = "checklist",
  REQUIREMENTS = "requirements",
  PRODUCTS = "products",
  SURVEY = "questionnaire",
  SCORECARD = "scorecard",
  SELECTION = "selection",
}
