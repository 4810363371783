// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e5zfAvd8cxQ6MNgwwZL7{align-items:center;display:flex;justify-content:space-between;width:98px}`, "",{"version":3,"sources":["webpack://./src/sections/selection-tab-v2/components/selection-report/components/initiative-details-card/components/checklist-status-display.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,6BAAA,CACA,UAAA","sourcesContent":[".statusContainer {\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  width: 98px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statusContainer": `e5zfAvd8cxQ6MNgwwZL7`
};
export default ___CSS_LOADER_EXPORT___;
