import React, { useContext } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Collapse } from "@material-ui/core";
import { TypographyComponent } from "gx-npm-ui";

import { RequirementItem } from "../../../../../../../app.types";
import styles from "./scorecard-req-cat-items.styles.module.scss";
import { CarouselContext } from "../../../carousel.context";

type ScorecardRequirementItemProps = {
  index: number;
  reqItems: RequirementItem[];
  displayProductsCount: number;
};

const ScorecardRequirementItem = ({ index, reqItems, displayProductsCount }: ScorecardRequirementItemProps) => {
  const { t } = useTranslation();
  const { reqCatOpenIdxList } = useContext(CarouselContext);

  const isListOpen = reqCatOpenIdxList.includes(index);

  return (
    <Collapse className={styles.reqItemNameContainer} in={isListOpen}>
      <ul className={classNames(styles.reqItemList, displayProductsCount === 0 && styles.rightBorder)}>
        {reqItems.map(({ name, id }) => (
          <li key={id} className={classNames(styles.reqItem, "gx-get-element-height")}>
            <TypographyComponent color={"coal"}>
              {name || (
                <TypographyComponent rootClassName={styles.italic} color={"coal"}>
                  {t("Untitled requirement")}
                </TypographyComponent>
              )}
            </TypographyComponent>
          </li>
        ))}
      </ul>
    </Collapse>
  );
};

export default ScorecardRequirementItem;
