import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import { ScoringMode } from "gx-npm-lib";

import { ListHeaderExpandButton } from "../../../../../../../ui/dragAndDropList/header";
import EvaluationScorecardAssignmentsAvatar from "../../../evaluation-scorecard-assignments-avatar.component";
import { Requirement } from "../../../../../../../app.types";
import styles from "./scorecard-req-cat.styles.module.scss";
import { CarouselContext } from "../../../carousel.context";

type ScorecardReqCatProps = {
  reqCat: Requirement;
  reqCatCount: number;
  index: number;
  displayedProductsCount: number;
  onAvatarClick?: () => void;
  isOwner: boolean;
  scoringMode: ScoringMode;
  isWithinDockedRow?: boolean;
};

const ScorecardReqCat: React.FC<ScorecardReqCatProps> = ({
  reqCat,
  reqCatCount,
  index,
  displayedProductsCount,
  onAvatarClick,
  isOwner,
  scoringMode,
  isWithinDockedRow = false,
}) => {
  const { t } = useTranslation();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [previousYPos, setPreviousYPos] = useState(0);
  const { onExpandToggleReqCatClick, reqCatOpenIdxList, setDockedReqCatIdx, dockedReqCatIdx, onReqDockingTrigger } =
    useContext(CarouselContext);

  useEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.boundingClientRect.y <= 240 && entry.boundingClientRect.y > -100) {
          const currentYPos = entry.boundingClientRect.y;
          if (currentYPos < previousYPos) {
            onReqDockingTrigger(index);
          } else if (currentYPos > previousYPos) {
            if (index === 0) {
              if (currentYPos < 100 && dockedReqCatIdx !== -1) {
                onReqDockingTrigger(-1);
              }
            } else {
              onReqDockingTrigger(index - 1);
            }
          }
          setPreviousYPos(currentYPos);
        }
      },
      {
        root: null,
        rootMargin: "-120px 0px 0px 0px",
        threshold: 0.01,
      }
    );

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [index, previousYPos, reqCatOpenIdxList, setDockedReqCatIdx, onReqDockingTrigger, dockedReqCatIdx]);

  if (!reqCat) {
    return null;
  }

  const isOpen = reqCatOpenIdxList.includes(index);
  const isFirstOrLast = index === 0 || index === reqCatCount - 1;
  const hasNoProducts = displayedProductsCount === 0;

  return (
    <div
      ref={elementRef}
      className={classNames(
        "gx-get-element-height",
        "gx-req-root-category",
        "gcom3790-ff",
        hasNoProducts && styles.hasNoProducts,
        isFirstOrLast && styles.noRightBorder,
        isOpen && styles.reqRootCategoryOpen,
        styles.reqRootCategory,
        isWithinDockedRow && styles.docked
      )}
    >
      <ListHeaderExpandButton expand={isOpen} setExpand={() => onExpandToggleReqCatClick(index)} />
      <div className={styles.reqRootCategoryNameWeightWrapper}>
        <TypographyComponent boldness={"medium"} rootClassName={classNames(styles.reqRootCategoryName)} styling={"p2"}>
          {reqCat.name || <span>{t("Untitled category")}</span>}
        </TypographyComponent>
        <TypographyComponent boldness={"semi"} rootClassName={styles.reqRootCategoryWeight} styling={"p4"}>
          {reqCat.weight}%
        </TypographyComponent>
      </div>
      <EvaluationScorecardAssignmentsAvatar
        categoryEvaluators={reqCat.evaluators}
        id={reqCat.id}
        isOwner={isOwner}
        mode={scoringMode}
        onClick={onAvatarClick}
      />
    </div>
  );
};

export default ScorecardReqCat;
