import { InitUserRole, useCaptureEventsV2, useUserInitAccess, useWebSocket } from "gx-npm-lib";
import { updateStateSubject } from "gx-npm-messenger-util";
import { SnackbarBanner } from "gx-npm-ui";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { ClientEvent } from "../../app.constants";
import TabSectionLoader from "../../ui/loader/tabSectionLoader";
import { EvaluationScorecard } from "./components/scorecard/evaluation-scorecard.component";
import ScoringTeamFtux from "./components/scoringTeamFtux/scoring-team-ftux.component";
import StartScorecard from "./components/start-scorecard/start-scorecard.component";
import { computeRequirementsItemCount, loadScorecardTabData } from "./scorecard-tab.lib";
import TabPanelComponent from "../../ui/tab-panel/tab-panel.component";
import { CarouselContextProvider } from "./components/scorecard/carousel.context";

const ScorecardTab = () => {
  const { initiativeId = "" } = useParams();
  const [hasError, setHasError] = useState(false);
  const [hasScorecard, setHasScorecard] = useState(false);
  const [hasSelectedScoringAssignment, setHasSelectedScoringAssignment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingInitAccess, setIsLoadingInitAccess] = useState(true);
  const [isLoadingScorecard, setIsLoadingScorecard] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [initStatus, setInitStatus] = useState("");
  const [latestWSMessage, setLatestWSMessage] = useState({});
  const [requirements, setRequirements] = useState([]);
  const [productScorecards, setProductScorecards] = useState([]);
  const [scoringLevel, setScoringLevel] = useState("NONE");
  const [scoringMode, setScoringMode] = useState("NONE");
  const [totalReqCatCount, setTotalReqCatCount] = useState(0);
  const [totalReqCatWeight, setTotalReqCatWeight] = useState(0);
  const [totalReqItemCount, setTotalReqItemCount] = useState(0);
  const [wsMessage] = useWebSocket();
  const captureEvents = useCaptureEventsV2();
  const { hasLoadedAccess, role } = useUserInitAccess(initiativeId);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    loadScorecardTabData(initiativeId, onSuccessLoadData, onErrorLoading).finally(() => setIsLoadingScorecard(false));
  }, [initiativeId]);

  useEffect(() => {
    setIsLoading(isLoadingInitAccess || isLoadingScorecard);
  }, [isLoadingInitAccess, isLoadingScorecard]);

  useEffect(() => {
    if (!requirements?.length) {
      return;
    }
    setTotalReqCatCount(requirements.length);
    setTotalReqCatWeight(requirements.reduce((acc, curr) => Math.round((acc + (curr.weight || 0)) * 10) / 10, 0) || 0);
    setTotalReqItemCount(computeRequirementsItemCount(requirements));
  }, [requirements]);

  useEffect(() => {
    if (!hasLoadedAccess) {
      setIsLoadingInitAccess(true);
      return;
    }
    setIsLoadingInitAccess(false);
    setIsOwner(role === InitUserRole.OWNER);
    setIsViewer(role === InitUserRole.VIEWER);
  }, [hasLoadedAccess, role]);

  useEffect(() => {
    if (!wsMessage || wsMessage.initiativeId !== initiativeId) {
      return;
    }
    setLatestWSMessage({ ...wsMessage });
  }, [initiativeId, wsMessage]);

  useEffect(() => {
    if (latestWSMessage.hasBeenInterpreted) {
      return;
    }
    switch (latestWSMessage.event) {
      case "INITIATIVE_TEAM_MEMBER_ROLE_CHANGED":
      case "INITIATIVE_TEAM_MEMBER_REMOVED":
      case "REQUIREMENTS_SCORING_ASSIGNMENT_UPDATED":
      case "REQUIREMENTS_SCORING_MODE_UPDATED":
        loadScorecardTabData(initiativeId, onSuccessLoadData, onErrorLoading);
        break;
      default:
        break;
    }
    setLatestWSMessage((prev) => {
      return { ...prev, hasBeenInterpreted: true };
    });
  }, [initiativeId, latestWSMessage]);

  const onSuccessLoadData = (data) => {
    setInitStatus(data.initStatus);
    setHasSelectedScoringAssignment(!!data.hasSelectedScoringAssignment);
    setRequirements(data.requirements || []);
    setScoringLevel(data.scoringLevel || "");
    setProductScorecards(data.productScorecards || []);
    setScoringMode(data.scoringMode || "");
  };

  useEffect(() => {
    const hasScoringLevel = ["CATEGORY", "REQUIREMENT"].includes(scoringLevel);
    const isOutOfPlanningStatus = ["EVAL_STARTED", "SELECTED"].includes(initStatus);
    setHasScorecard(hasScoringLevel && isOutOfPlanningStatus);
  }, [scoringLevel, initStatus]);

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    captureEvents([{ eventType: ClientEvent.INITIATIVE_SCORECARD_PAGE_VIEWED, metaData: { initiativeId } }]);
  }, [captureEvents, initiativeId]);

  const onErrorLoading = (_error) => {
    setHasError(true);
  };

  const handleClickAssignEvaluators = () => {
    updateStateSubject("EVAL_HEADER_SOURCE_PAGE", { sourcePage: "scorecard", target: "SCORING_ASSIGNMENTS" });
    singleSpa.navigateToUrl(`/s/evaluation/${initiativeId}/scorecard/team-management`);
  };

  const handleDrawerClose = async () => {
    await handleRefreshData();
  };

  const handleRefreshData = async () => {
    await loadScorecardTabData(initiativeId, onSuccessLoadData, onErrorLoading);
  };

  if (isLoading) {
    return (
      <TabPanelComponent>
        <TabSectionLoader />
      </TabPanelComponent>
    );
  }
  return (
    <TabPanelComponent>
      {!hasScorecard && (
        <StartScorecard
          isOwner={isOwner}
          onRefreshData={handleRefreshData}
          totalReqCatCount={totalReqCatCount}
          totalReqCatWeight={totalReqCatWeight}
          totalReqItemCount={totalReqItemCount}
        />
      )}
      {hasScorecard && (
        <Fragment>
          {!hasSelectedScoringAssignment && (
            <ScoringTeamFtux onClick={handleClickAssignEvaluators} isViewOnly={!isOwner} />
          )}
          <div>
            <CarouselContextProvider>
              <EvaluationScorecard
                categories={requirements}
                isFtuxView={!hasSelectedScoringAssignment}
                isOwner={isOwner}
                isViewer={isViewer}
                onAvatarClick={handleClickAssignEvaluators}
                onError={() => setHasError(true)}
                products={productScorecards}
                setProducts={(updatedProducts) => setProductScorecards(updatedProducts)}
                requirements={requirements}
                scoringLevel={scoringLevel}
                scoringMode={scoringMode}
                handleClose={handleDrawerClose}
              />
            </CarouselContextProvider>
          </div>
        </Fragment>
      )}
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
    </TabPanelComponent>
  );
};

export default ScorecardTab;
