import { createContext, ReactNode, useState } from "react";
import { Category, FilterContextValue } from "../../survey-table.constants";

const FilterContext = createContext<FilterContextValue>({} as FilterContextValue);

const FilterContextProvider = ({ children }: { children: ReactNode }) => {
  const [priorityId, setPriorityId] = useState<string[]>([]);
  const [responseOptionIds, setResponseOptionIds] = useState<string[]>([]);
  const [productId, setProductId] = useState<string>("");
  const [priorityFilteredCategories, setPriorityFilteredCategories] = useState<Category[]>([]);
  const [responseFilteredCategories, setResponseFilteredCategories] = useState<Category[]>([]);

  const contextValue = {
    priorityFilteredCategories,
    responseFilteredCategories,
    priorityId,
    responseOptionIds,
    productId,
    setPriorityId,
    setProductId,
    setResponseOptionIds,
    setPriorityFilteredCategories,
    setResponseFilteredCategories,
  };

  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>;
};

export { FilterContext, FilterContextProvider };
