import { colorPalette } from "gx-npm-common-styles";
import { Dispatch, SetStateAction } from "react";
import { ChartData } from "./components/chart/chart.constants";

export type Product = {
  hasRecipients: boolean;
  id: string;
  imageLoc: string;
  name: string;
  productState: string;
  surveyStatus: string;
  categories: Record<string, UploadedCategory>;
  isSubmitted: boolean;
  files: Record<string, UploadedFile>;
};

export type ResponseOption = {
  id: string;
  name: string;
};

export type Requirement = {
  description: string;
  id: string;
  name: string;
  priority: number;
};

export type Category = {
  id: string;
  name: string;
  requirements: Requirement[];
};

export type RequestedDocument = {
  id: string;
  requestedDocName: string;
};

export type UploadedRequirement = {
  comment: string;
  responseOptionId: string;
};

type UploadedCategory = {
  comment: string;
  requirements: Record<string, UploadedRequirement>;
};

export type UploadedFile = {
  fileId: string;
  fileName: string;
  isSkipped: boolean;
};

export enum SurveyStatus {
  DEFAULT = "",
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
}

export const LegendColors: string[] = [
  colorPalette.brand.gartnerBlue.hex,
  colorPalette.interactions.lightBlue.hex,
  colorPalette.brand.sky.hex,
  colorPalette.brand.surf.hex,
  colorPalette.neutrals.grey06.hex,
];

export type RequirementPopperContent = {
  product: Product;
  uploadedRequirement: UploadedRequirement;
  requirementResponse: string;
  requirement: Requirement;
};

export type ChartPopperContent = {
  product: Product;
  chartData: ChartData;
  prioritiesCount: Record<number, number>;
};

export type SurveyTableContextValue = {
  surveyProducts: Product[];
  surveyResponseOptions: ResponseOption[];
  surveyCategories: Category[];
  filteredCategories: Category[];
  setSurveyProducts: Dispatch<SetStateAction<Product[]>>;
  setSurveyCategories: Dispatch<SetStateAction<Category[]>>;
  setSurveyResponseOptions: Dispatch<SetStateAction<ResponseOption[]>>;
  setFilteredCategories: Dispatch<SetStateAction<Category[]>>;
};

export type FilterContextValue = {
  priorityId: string[];
  responseOptionIds: string[];
  productId: string;
  priorityFilteredCategories: Category[];
  responseFilteredCategories: Category[];
  setPriorityId: Dispatch<SetStateAction<string[]>>;
  setResponseOptionIds: Dispatch<SetStateAction<string[]>>;
  setProductId: Dispatch<SetStateAction<string>>;
  setPriorityFilteredCategories: Dispatch<SetStateAction<Category[]>>;
  setResponseFilteredCategories: Dispatch<SetStateAction<Category[]>>;
};
