import { Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { TextLink } from "../../../textLink";
import { TypographyComponent } from "../../../typography/typography.component";
import styles from "./research-section.styles.module.scss";

type ResearchSectionProps = {
  description: string;
  href: string;
  icon?: ReactElement;
  researchPoints?: string[];
  templateName: string;
  title: string;
};
const ResearchSectionComponent = ({
  description = "",
  href = "",
  icon = <Fragment />,
  researchPoints = [],
  templateName = "",
  title = "",
}: ResearchSectionProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <TypographyComponent color="berry" element="h4" rootClassName={styles.header} styling="h5">
        {icon}
        {title}
      </TypographyComponent>
      <TypographyComponent color="quartz" rootClassName={styles.description} styling="p2">
        {description}
      </TypographyComponent>
      {researchPoints.length > 0 && (
        <ul className={styles.list}>
          {researchPoints.map((point) => {
            return (
              <li key={point} className={styles.listItem}>
                <TypographyComponent color="quartz" styling="p2">
                  {point}
                </TypographyComponent>
              </li>
            );
          })}
        </ul>
      )}
      <div className={styles.divider} />
      <TypographyComponent boldness="medium" color="stone" rootClassName={styles.learnMore} styling="p4">
        {t("Learn more")}
      </TypographyComponent>
      <div>
        <TextLink
          rootClassName={styles.linkText}
          href={href}
          target="_blank"
          text={`${title} ${t("for")} ${templateName}`}
          variant={"dark"}
        />
      </div>
    </div>
  );
};

export default ResearchSectionComponent;
