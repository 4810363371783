import { useEffect, useState, useRef, useContext, useMemo, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, MiniButton, ProductLogo, TypographyComponent } from "gx-npm-ui";
import { ClickAwayListener, FormControl, InputLabel, MenuItem, Popper, Select, SelectChangeEvent } from "@mui/material";
import styles from "./product-response-filter.styles.module.scss";
import classNames from "classnames";
import { CheckmarkIconV2, ChevronDownIcon, CloseIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { SurveyTableContext } from "../../context/survey-table/survey-table.context";
import { FilterContext } from "../../context/filter/filter.context";

export const ProductResponseFilterComponent = () => {
  const { t } = useTranslation();
  const popperAnchor = useRef<HTMLDivElement | null>(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [selectedResponseOptions, setSelectedResponseOptions] = useState<string[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState<boolean>(false);
  const { surveyResponseOptions, surveyProducts, surveyCategories } = useContext(SurveyTableContext);
  const [isClickedAway, setIsClickedAway] = useState(false);
  const { productId, responseOptionIds, setProductId, setResponseOptionIds, setResponseFilteredCategories } =
    useContext(FilterContext);

  const productName = useMemo(() => {
    return surveyProducts.find((product) => product.id === productId)?.name;
  }, [productId, surveyProducts]);

  const responseOptionsText = useMemo(() => {
    return responseOptionIds.map((id) => surveyResponseOptions.find((option) => option.id === id)?.name).join(", ");
  }, [responseOptionIds, surveyResponseOptions]);

  const handleProductDropdownChange = (e: SelectChangeEvent<string>) => {
    setSelectedProductId(e.target.value);
  };

  const handleProductDropdownOpen = () => {
    setIsProductDropdownOpen(true);
  };
  const handleProductDropdownClose = () => {
    setIsProductDropdownOpen(false);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    const updatedOption = e.target.name;
    if (isChecked) {
      setSelectedResponseOptions((prev) => [...prev, updatedOption]);
    } else {
      setSelectedResponseOptions((prev) => prev.filter((option) => option !== updatedOption));
    }
  };

  const handleProductPillClick = () => {
    if (!isClickedAway) {
      setIsPopperOpen((prev) => !prev);
    }
  };

  const handleClickAway = () => {
    if (!isProductDropdownOpen) {
      setIsPopperOpen(false);
      setIsClickedAway(true);
      setTimeout(() => {
        setIsClickedAway(false);
      }, 0);
    }
  };

  const clearAllFilters = () => {
    setProductId("");
    setResponseOptionIds([]);
    setIsPopperOpen(false);
    setResponseFilteredCategories([]);
  };

  const applyFilters = () => {
    if (selectedProductId.length === 0 || selectedResponseOptions.length === 0) {
      setSelectedProductId("");
      setSelectedResponseOptions([]);
      clearAllFilters();
      return;
    }
    setProductId(selectedProductId);
    setResponseOptionIds(selectedResponseOptions);
    setIsPopperOpen(false);

    const selectedProductDetails = surveyProducts.find((product) => product.id === selectedProductId);
    const filteredCategories = surveyCategories.map((category) => {
      return {
        ...category,
        requirements: category.requirements.filter((requirement) => {
          const responseOptionId =
            selectedProductDetails?.categories[category.id]?.requirements[requirement.id]?.responseOptionId || "";
          return selectedResponseOptions.includes(responseOptionId);
        }),
      };
    });
    setResponseFilteredCategories(filteredCategories);
  };

  useEffect(() => {
    setSelectedResponseOptions([...responseOptionIds]);
  }, [responseOptionIds]);

  useEffect(() => {
    if (isPopperOpen) {
      setSelectedProductId(productId);
    } else {
      setSelectedResponseOptions([...responseOptionIds]);
    }
  }, [isPopperOpen, responseOptionIds, productId]);

  return (
    <span className={styles.root}>
      <div
        ref={popperAnchor}
        onClick={handleProductPillClick}
        className={classNames(
          styles.pill,
          isPopperOpen && styles.active,
          productId.length > 0 && styles.appliedFilterState
        )}
        role={"button"}
        tabIndex={0}
        onKeyDown={handleProductPillClick}
      >
        <TypographyComponent styling={"p3"}>{t("Vendor response")}</TypographyComponent>
        {responseOptionIds.length > 0 && (
          <TypographyComponent rootClassName={styles.truncateFilterValue} styling={"p3"} boldness={"medium"}>
            {`: `}
            {productName}
            {` - `}
            {responseOptionsText}
          </TypographyComponent>
        )}
        <div className={classNames(styles.iconWrapper, isPopperOpen && styles.rotateChevron)}>
          <ChevronDownIcon />
        </div>
      </div>
      <ClickAwayListener onClickAway={handleClickAway} mouseEvent={"onMouseUp"}>
        <Popper
          open={isPopperOpen}
          anchorEl={popperAnchor.current}
          placement={"bottom"}
          className={styles.popper}
          disablePortal={true}
        >
          <FormControl fullWidth>
            <InputLabel
              classes={{
                root: styles.labelRoot,
                shrink: styles.labelShrink,
              }}
            >
              {t("Select a vendor")}
            </InputLabel>
            <Select
              label={t("Select a vendor")}
              aria-labelledby={"Select a vendor"}
              className={styles.dropdownPaper}
              fullWidth
              onOpen={handleProductDropdownOpen}
              onClose={handleProductDropdownClose}
              onChange={handleProductDropdownChange}
              classes={{
                root: styles.selectRoot,
              }}
              MenuProps={{
                className: styles.dropdownPaper,
              }}
              value={selectedProductId !== "" ? selectedProductId : ""}
            >
              {surveyProducts.map((product, index) => {
                return (
                  <MenuItem key={index} value={product.id}>
                    <div className={styles.itemContainer}>
                      <ProductLogo name={product.name} logo={product.imageLoc} rootClassName={styles.productLogo} />
                      <TypographyComponent styling={"p3"} rootClassName={styles.productName}>
                        {product.name}
                      </TypographyComponent>
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TypographyComponent styling={"p5"} rootClassName={styles.checklistHeading} color={"iron"}>
            {t("Responded with")}
          </TypographyComponent>
          {surveyResponseOptions.map((option) => (
            <div key={option.id} className={styles.wrapper}>
              <Checkbox
                label={option.name}
                onChange={handleCheckboxChange}
                name={option.id}
                checked={selectedResponseOptions.includes(option.id)}
                rootClassName={styles.checkboxContainer}
              />
            </div>
          ))}
          <div className={styles.footerContainer}>
            <MiniButton rootClassName={classNames(styles.clearAllBtn, styles.btn)} onClick={clearAllFilters}>
              <CloseIcon fillPath={colorPalette.neutrals.iron.hex} />
              <TypographyComponent styling={"p3"} boldness={"medium"} color={"defaultCta"}>
                {t("Clear all")}
              </TypographyComponent>
            </MiniButton>
            <MiniButton rootClassName={classNames(styles.applyBtn, styles.btn)} onClick={applyFilters}>
              <CheckmarkIconV2 />
              <TypographyComponent styling={"p3"} boldness={"medium"} color={"defaultCta"}>
                {t("Apply")}
              </TypographyComponent>
            </MiniButton>
          </div>
        </Popper>
      </ClickAwayListener>
    </span>
  );
};
