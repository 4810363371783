import React from "react";
import propTypes from "prop-types";
import styles from "./survey-team-list.styles";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { TypographyComponent } from "gx-npm-ui";
import SurveyTeamListItem from "./survey-team-list-item.component";
import classNames from "classnames";

const proptypes = {
  recipients: propTypes.array,
  onRemoveTeamMember: propTypes.func,
  onResendInvite: propTypes.func,
  resendEmailSuccess: propTypes.string,
  removeUserSuccess: propTypes.bool,
  addEmailSuccess: propTypes.string,
};

const useStyles = makeStyles(styles);
const SurveyTeamList = ({
  recipients,
  onRemoveTeamMember = () => {},
  onResendInvite = () => {},
  resendEmailSuccess = "",
  addEmailSuccess = "",
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let teamList = [];

  if (recipients?.length > 0) {
    teamList = recipients;
  }

  return (
    <>
      <div className={classNames(classes.recipientList)}>
        {teamList.length > 0 &&
          teamList.map((recipient, index) => {
            return (
              <SurveyTeamListItem
                key={index}
                email={recipient.email}
                fullName={recipient.fullName}
                hasViewed={recipient.hasViewed}
                onRemoveTeamMember={onRemoveTeamMember}
                onResendInvite={onResendInvite}
                resendEmailSuccess={resendEmailSuccess}
                addEmailSuccess={addEmailSuccess}
              />
            );
          })}
        {teamList.length === 0 && (
          <TypographyComponent color={"iron"} rootClassName={classes.noContacts} styling={"p4"}>
            {t("No contacts have been added for this vendor.")}
          </TypographyComponent>
        )}
      </div>
    </>
  );
};

SurveyTeamList.propTypes = proptypes;
export default SurveyTeamList;
