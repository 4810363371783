type ScoresRefCallbackType = {
  scoresContainerWidth: number;
  visibleProductsCount: number;
  currentPos: number;
  hasAwardedProduct: boolean;
  maxProdCols: number;
  hasReachedEnd: boolean;
};

type ColumnPercentsType = {
  [key: number]: number;
};

export const scoresRefCallback = ({
  scoresContainerWidth,
  visibleProductsCount,
  currentPos,
  hasAwardedProduct,
  maxProdCols,
  hasReachedEnd,
}: ScoresRefCallbackType) => {
  if (scoresContainerWidth === 0 || visibleProductsCount === 0) {
    return 0;
  }

  const AWARDED_COLUMN_PERCENTS: Readonly<ColumnPercentsType> = Object.freeze({
    1: 1,
    2: 0.6,
    3: 0.44,
    4: 0.3333,
  });
  const NOT_AWARDED_HAS_AWARDED_COLUMN_PERCENTS: Readonly<ColumnPercentsType> = Object.freeze({
    1: 1,
    2: 0.4,
    3: 0.28,
    4: 0.222222,
  });
  const NOT_AWARDED_NO_AWARDED_COLUMN_PERCENTS: Readonly<ColumnPercentsType> = Object.freeze({
    1: 1,
    2: 0.5,
    3: 0.3333,
    4: 0.25,
  });

  const notAwardedItemPercents = hasAwardedProduct
    ? NOT_AWARDED_HAS_AWARDED_COLUMN_PERCENTS
    : NOT_AWARDED_NO_AWARDED_COLUMN_PERCENTS;

  const awardedItemWidth = scoresContainerWidth * AWARDED_COLUMN_PERCENTS[maxProdCols] - 1;
  const notAwardedItemWidth = scoresContainerWidth * notAwardedItemPercents[maxProdCols];

  let scoresXPos = 0;

  if (currentPos > 0 && maxProdCols > 1) {
    if (hasAwardedProduct) {
      if (currentPos === 1) {
        scoresXPos = awardedItemWidth;
      } else {
        const notAwardedTravelDistance = notAwardedItemWidth + notAwardedItemWidth / 2;
        const offsetCurrentPosition = currentPos - 1;

        if (!hasReachedEnd) {
          scoresXPos = awardedItemWidth + notAwardedTravelDistance * offsetCurrentPosition;
        } else if (hasReachedEnd) {
          const notAwardedEndTravelDistance =
            notAwardedTravelDistance * offsetCurrentPosition - notAwardedItemWidth / 2;
          const isProductLengthOdd = visibleProductsCount % 2 !== 0;

          if (isProductLengthOdd) {
            scoresXPos = awardedItemWidth + notAwardedTravelDistance * offsetCurrentPosition;
          } else {
            scoresXPos = awardedItemWidth + notAwardedEndTravelDistance;
          }
        }
      }
    } else {
      scoresXPos = notAwardedItemWidth * currentPos;
    }
  } else {
    if (maxProdCols > 1) {
      scoresXPos = 0;
    } else {
      scoresXPos = scoresContainerWidth * currentPos;
    }
  }

  return scoresXPos;
};
