// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oUzjHoXVAsul0mmuauGD{display:flex;align-items:center;margin:0;padding:16px 24px;width:100%;justify-content:left;height:100%}.oUzjHoXVAsul0mmuauGD .vOtOlGpc6HdDPqVhXwqA{padding-right:16px}.oUzjHoXVAsul0mmuauGD .sVUYrPvQ6AinXP9bfTLJ{cursor:pointer;font-size:14px}.oUzjHoXVAsul0mmuauGD .sVUYrPvQ6AinXP9bfTLJ>svg{display:none}.zWXy9lEHvEQ8q4iLTAAR{filter:grayscale(1);background-size:9.9px 9.9px;background-image:linear-gradient(135deg, transparent 42.86%, rgba(187, 185, 184, 0.7) 42.86%, rgba(187, 185, 184, 0.7) 50%, transparent 50%, transparent 92.86%, rgba(187, 185, 184, 0.7) 92.86%, rgba(187, 185, 184, 0.7) 100%)}`, "",{"version":3,"sources":["webpack://./src/sections/survey/survey-table/utils/create-footer-rows/create-footer-rows.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CACA,UAAA,CACA,oBAAA,CACA,WAAA,CAEA,4CACE,kBAAA,CAGF,4CACE,cAAA,CACA,cAAA,CAEA,gDACE,YAAA,CAKN,sBACE,mBAAA,CACA,2BAAA,CACA,gOAAA","sourcesContent":[".documentContainer {\n  display: flex;\n  align-items: center;\n  margin: 0;\n  padding: 16px 24px;\n  width: 100%;\n  justify-content: left;\n  height: 100%;\n\n  .icon {\n    padding-right: 16px;\n  }\n\n  .documentLink {\n    cursor: pointer;\n    font-size: 14px;\n\n    > svg {\n      display: none;\n    }\n  }\n}\n\n.screenedOut {\n  filter: grayscale(1);\n  background-size: 9.90px 9.90px;\n  background-image: linear-gradient(135deg, transparent 42.86%, rgba(187, 185, 184, 0.7) 42.86%, rgba(187, 185, 184, 0.7) 50%, transparent 50%, transparent 92.86%, rgba(187, 185, 184, 0.7) 92.86%, rgba(187, 185, 184, 0.7) 100%);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentContainer": `oUzjHoXVAsul0mmuauGD`,
	"icon": `vOtOlGpc6HdDPqVhXwqA`,
	"documentLink": `sVUYrPvQ6AinXP9bfTLJ`,
	"screenedOut": `zWXy9lEHvEQ8q4iLTAAR`
};
export default ___CSS_LOADER_EXPORT___;
