// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gwUFEHpFpyLbwuRa2HNj{width:100%}.AIDY4e2GTcDvEKchhc7x{padding-left:63px;margin:0;list-style-type:none;box-shadow:inset 0px -1px 0px var(--color-neutrals-stone)}.AIDY4e2GTcDvEKchhc7x .qFyW5nhTA5AH9auUOlpw{line-height:24px;box-shadow:inset 0 -1px 0 var(--color-neutrals-silver);padding:16px 32px 16px 0;border-right:1px solid var(--color-neutrals-silver)}.AIDY4e2GTcDvEKchhc7x .qFyW5nhTA5AH9auUOlpw ._Nw5CMHHPk_Z58tVmpEN{font-style:italic}.AIDY4e2GTcDvEKchhc7x.vaBJu2B9NyxTXwo73Cgw{border-right:var(--color-neutrals-silver) 1px solid}`, "",{"version":3,"sources":["webpack://./src/sections/scorecard-tab/components/scorecard/evaluation-scorecard/scorecard-requirements/scorecard-req-cat-items/scorecard-req-cat-items.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,oBAAA,CACA,yDAAA,CAEA,4CACE,gBAAA,CACA,sDAAA,CACA,wBAAA,CACA,mDAAA,CAEA,kEACE,iBAAA,CAKN,2CACE,mDAAA","sourcesContent":[".reqItemNameContainer {\n  width: 100%;\n}\n\n.reqItemList {\n  padding-left: 63px;\n  margin: 0;\n  list-style-type: none;\n  box-shadow: inset 0px -1px 0px var(--color-neutrals-stone);\n\n  .reqItem {\n    line-height: 24px;\n    box-shadow: inset 0 -1px 0 var(--color-neutrals-silver);\n    padding: 16px 32px 16px 0;\n    border-right: 1px solid var(--color-neutrals-silver);\n\n    .italic {\n      font-style: italic;\n    }\n  }\n}\n\n.reqItemList.rightBorder {\n  border-right: var(--color-neutrals-silver) 1px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reqItemNameContainer": `gwUFEHpFpyLbwuRa2HNj`,
	"reqItemList": `AIDY4e2GTcDvEKchhc7x`,
	"reqItem": `qFyW5nhTA5AH9auUOlpw`,
	"italic": `_Nw5CMHHPk_Z58tVmpEN`,
	"rightBorder": `vaBJu2B9NyxTXwo73Cgw`
};
export default ___CSS_LOADER_EXPORT___;
